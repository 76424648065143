import SidebarLogo from "./SideBarLogo.svg";
import DateIcon from "./date_icon.svg";
import TimeIcon from "./time_icon.svg";
import FolloUpIcon from "./follow_up_icon.svg";
import DoctorAvatar from "./doctor-avatar.svg";
import UserAvatar from "./user-avatar.svg";
import ThankYouIcon from "./thank-you-icon.svg";
import ChatAvatar from "./chat-avatar.svg";
import Step1 from "./step_1.svg";
import Step2 from "./step_2.svg";
import Step3 from "./step_3.svg";
import Step4 from "./step_4.svg";
import Step5 from "./step_5.svg";
import Step6 from "./step_6.svg";
import MaleCharacter from "./male-standing.png";
import FemaleCharacter from "./female-standing.png";
// import FemaleDoctor from "./female_correct.mp4";
import FemaleDoctor from "./Female-Video.mp4";
import MaleDoctor from "./Male-Video.mp4";
import femaleAvatar from "./female-avatar.jpg";
import background from "./bg.png";
import cardBackground from "./cardBackground.png";
import logo from "./logo.png";
import LOGIN_BG from "./bg.png";
import LOGIN_ASSET from "./doc-vector.png";
import FB_ICON from "./facebookIcon.svg";
import GOOGLE_ICON from "./googleIcon.svg";
import backgroundOld from "./bg2.jpg";
import BODY from "./body.svg";
import FEMALE_DOCTOR_AVATAR from "./femaleDoctoreAvatar.jpeg";
import MALE_DOCTOR_AVATAR from "./maleDoctorAvatar.jpeg";

// import MaleDoctor from "./MaleDoctorTalking.mp4";

const IMAGES = {
	FEMALE_AVATAR: femaleAvatar,
	SIDEBAR_LOGO: SidebarLogo,
	MEDISTREAMLINE_LOGO: logo,
	DATE_ICON: DateIcon,
	TIME_ICON: TimeIcon,
	FOLLOW_UP_ICON: FolloUpIcon,
	DOCTOR_AVATAR: DoctorAvatar,
	USER_AVATAR: UserAvatar,
	THANKYOU_ICON: ThankYouIcon,
	CHAT_AVATAR: ChatAvatar,
	STEP_ICON_1: Step1,
	STEP_ICON_2: Step2,
	STEP_ICON_3: Step3,
	STEP_ICON_4: Step4,
	STEP_ICON_5: Step5,
	STEP_ICON_6: Step6,
	MALE_CHARACTER: MaleCharacter,
	FEMALE_CHARACTER: FemaleCharacter,
	FEMALE_DOCTOR: FemaleDoctor,
	MALE_DOCTOR: MaleDoctor,

	BACKGROUND: background,
	MINI_BACKGROUND: backgroundOld,
	CARD_BACKGROUND: cardBackground,
	LOGIN_BG,
	LOGIN_ASSET,
	FB_ICON,
	GOOGLE_ICON,
	BODY,
	FEMALE_DOCTOR_AVATAR,
	MALE_DOCTOR_AVATAR,
};

export default IMAGES;
