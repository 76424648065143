import PropTypes from 'prop-types'
import { Form } from "react-bootstrap";

export default function CheckBox({...props}) {
  return (
    <Form.Check
      id={props.id}
      inline={props.inline}
      label={props.label}
      value={props.value}
      name={props.name}
      type={props.type}
      onChange={props.onChange}
      ref={props.inputRef}
      {...props}
      />
  )
}

CheckBox.propTypes = {
  id: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
}

CheckBox.defaultProps = {
  id:'',
  inline : false ,
  label:"",
  inputRef : null ,
  value :"",
  name:"checkbox",
  type: 'checkbox',
  onChange: ()=>{},
}