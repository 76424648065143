import React from "react";
import { Form } from "react-bootstrap";

const ChartCardWithDropdown = ({
  options,
  planOptions,
  planTypeOptions,
  selectedOption,
  selectedPlan,
  selectedPlanType,
  onFilterChange,
  onPlanChange,
  onPlanTypeChange,
  title,
  children,
}) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-end gap-2 align-items-center mb-3">
          <h4 className="card-title">{title}</h4>
          <Form.Group controlId="filterSelect" className="ms-auto">
            <Form.Select
              value={selectedOption}
              onChange={onFilterChange}
              className="form-select"
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          {planOptions && (
            <Form.Group controlId="planSelect">
              <Form.Select
                as="select"
                className="form-select"
                value={selectedPlan}
                onChange={onPlanChange}
              >
                <option>Select Plan</option>
                {planOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
          {planTypeOptions && (
            <Form.Group controlId="planTypeSelect">
              <Form.Select
                as="select"
                className="form-select"
                value={selectedPlanType}
                onChange={onPlanTypeChange}
              >
                <option>Select Plan Type</option>
                {planTypeOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default ChartCardWithDropdown;
