import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import LayoutNavbar from "./Navbar/LayoutNavbar";
import { ROUTER_PATH } from "../config";
import { IMAGES } from "../Assets";

export default function Layout() {
  const location = useLocation();
  return (
    <div
      className={
        // location.pathname === ROUTER_PATH.SIGN_IN
        //   ? "bg-white"
        // :s
        "main-container"
      }
      style={{
        background:
          location.pathname === ROUTER_PATH.SIGN_IN ? "white" : "none",
      }}
    >
      {/* {location.pathname !== ROUTER_PATH.PATIENT_DIAGNOSIS && ( */}
      {/* <video src={bgVideo} autoPlay loop muted className="bg-video" /> */}
      <img src={IMAGES.MINI_BACKGROUND} alt="background" className="bg-video" />
      {/* )} */}
      <LayoutNavbar />
      <Outlet />
    </div>
  );
}
