import React from "react";
import { GenericModal } from "../../Components";
import { CheckoutForm } from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";

const StripeModal = ({ isVisible, setIsVisible, stripePromiseKey, planId, selectedPlan, setSelectedPlan }) => {
  return (
    <GenericModal
      show={isVisible}
      onHide={() => setIsVisible(false)}
      className="p-5"
    >
      <Elements stripe={stripePromiseKey}>
        <CheckoutForm
          planId={planId}
          setIsVisible={setIsVisible}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}

          //   newCoachHire={newCoachHire}
          //   isLoading={isLoading}
          //   setIsLoading={setIsLoading}
        />
      </Elements>
    </GenericModal>
  );
};

export default StripeModal;
