/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box, Button, GenericLoader, Typography } from "../../Components";
import { Col, Row } from "react-bootstrap";
import { IMAGES } from "../../Assets";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { GetPatentAssessmentId } from "../../Redux/actions";
import { getLoggedInUser } from "../../utils/helper";
import { ROUTER_PATH } from "../../config";
import { TbEdit } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import useWindowSizeHook from "../../Components/WindowSizeHook";

const HealthProfile = () => {
  const dispatch = useDispatch();
  const medUser = getLoggedInUser();
  const navigate = useNavigate();
  const patient = useSelector((state) => state.patientProfile);
  console.log("patien", patient?.GET_PATIENT_ASSESSMENT_ID_DATA?.data);
  const patientData = patient?.GET_PATIENT_ASSESSMENT_ID_DATA?.data;
  const [expanded, setExpanded] = React.useState(false);
  const { width } = useWindowSizeHook();

  useEffect(() => {
    if (medUser?.userId)
      try {
        dispatch(GetPatentAssessmentId(medUser?.userId));
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div
    // Box
    //   width="100%"
    //   height="100%"
    //   maxHeight="35rem"
    //   maxWidth="202rem"
    //   borderRadius="1.375rem"
    //   background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
    //   shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
    //   backdropFilter="blur(15px)"
    //   className="p-5 mb-auto mb-5 w-100 chat-box-b"
    //
    >
      {patient?.GET_PATIENT_ASSESSMENT_ID_LOADING ? (
        <div className="d-flex justify-content-center align-items-center">
          <GenericLoader />
        </div>
      ) : (
        <div
          className={`d-flex justify-content-center  border rounded ${
            width < 1376 ? "p-3" : "p-5"
          }`}
          style={{
            background:
              "linear-gradient(rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.7) 100%)",
          }}
        >
          <div
            // shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
            // backdropFilter="blur(15px)"
            style={{ width: `${width < 1376 ? "90%" : "80%"}` }}
          >
            <div>
              <span
                className="d-flex align-items-center fw-bold text-primary cursor-pointer mb-3"
                onClick={() => navigate(ROUTER_PATH.HOME_PAGE)}
              >
                <FaArrowLeft className="me-2" /> Back
              </span>
            </div>
            <Typography fontSize="1.75rem" fontWeight="500" align="center">
              Health Profile
            </Typography>
            <Typography as="p" align="center">
              Now you can chat with your personal AI Doctor or consult Top Human
              Doctor.
            </Typography>

            <div className="d-flex justify-content-end pt-3">
              <Button
                className="btn btn-primary  text-white "
                onClick={() => {
                  navigate(
                    ROUTER_PATH.UPDATE_HEALTH_PROFILE.replace(
                      ":id",
                      medUser?.userId
                    )
                  );
                }}
              >
                <TbEdit className="fs-4 mb-1" /> Edit Health Details
              </Button>
            </div>
            <Box
              width="100%"
              height="100%"
              // shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
              // backdropFilter="blur(15px)"
              background="#fff"
              flex
              className={`mt-3 border rounded ${width < 1376 ? "p-3" : "p-5"}`}
            >
              <Row className="mb-4">
                <Col xs={12} md={5} lg={4}>
                  <div className="border p-2 rounded">
                    <Typography
                      fontSize="1.1rem"
                      fontWeight="300"
                      align={width < 1150 ? "start" : "end"}
                    >
                      {patientData?.age
                        ? `${patientData?.age} ${
                            patientData?.isMonth ? "Months" : "Years"
                          }`
                        : "N/A"}
                    </Typography>
                    <Typography
                      fontSize="0.9rem"
                      fontWeight="400"
                      align={width < 1150 ? "start" : "end"}
                    >
                      Age
                    </Typography>
                  </div>
                  <div className="border p-2 rounded mt-3">
                    <Typography
                      fontSize="1.1rem"
                      fontWeight="300"
                      align={width < 1150 ? "start" : "end"}
                    >
                      {patientData?.heightMeasurement
                        ? `${patientData?.heightMeasurement} ${
                            patientData?.isKg === false ? "(ft/in)" : "cm"
                          }`
                        : "N/A"}
                    </Typography>
                    <Typography
                      fontSize="0.9rem"
                      fontWeight="400"
                      align={width < 1150 ? "start" : "end"}
                    >
                      Height
                    </Typography>
                  </div>
                  <div className="border p-2 rounded mt-3">
                    <Typography
                      fontSize="1.1rem"
                      fontWeight="300"
                      align={width < 1150 ? "start" : "end"}
                    >
                      {patientData?.weightMeasurement
                        ? `${patientData?.weightMeasurement} ${
                            patientData?.isKg === false ? "(lbs)" : "kg"
                          }`
                        : "N/A"}
                    </Typography>
                    <Typography
                      fontSize="0.9rem"
                      fontWeight="400"
                      align={width < 1150 ? "start" : "end"}
                    >
                      Weight
                    </Typography>
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={2}
                  lg={4}
                  className={`d-flex justify-content-center ${
                    width < 1376 ? "p-3" : ""
                  }`}
                >
                  <img src={IMAGES.BODY} alt="human body" />
                </Col>
                <Col
                  xs={12}
                  lg={4}
                  md={5}
                  className="d-flex align-items-center"
                >
                  <div className="w-100">
                    <div className="border p-2 rounded">
                      <Typography
                        fontSize="1.1rem"
                        fontWeight="300"
                        align={width < 1150 ? "start" : "end"}
                      >
                        {patientData?.primaryRacial || "N/A"}
                      </Typography>
                      <Typography
                        fontSize="0.9rem"
                        fontWeight="400"
                        align={width < 1150 ? "start" : "end"}
                      >
                        Ethnicity
                      </Typography>
                    </div>
                    <div className="border p-2 rounded mt-3">
                      <Typography
                        fontSize="1.1rem"
                        fontWeight="300"
                        align={width < 1150 ? "start" : "end"}
                      >
                        {patientData?.sexAssigned || "N/A"}
                      </Typography>
                      <Typography
                        fontSize="0.9rem"
                        fontWeight="400"
                        align={width < 1150 ? "start" : "end"}
                      >
                        Sex assigned at birth
                      </Typography>
                    </div>
                    {patientData?.sexAssignedId === 102 && (
                      <div className="border p-2 rounded mt-3">
                        <Typography
                          fontSize="1.1rem"
                          fontWeight="300"
                          align={width < 1150 ? "start" : "end"}
                        >
                          {/* {patientData?.sexAssignedId === 102
                          ? patientData?.menstrualCycle
                          : "N/A"} */}
                        </Typography>
                        <Typography
                          fontSize="0.9rem"
                          fontWeight="400"
                          align={width < 1150 ? "start" : "end"}
                        >
                          Menstrual cycle
                        </Typography>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>

              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="py-2 fs-5 fw-normal"
                >
                  Medical History
                </AccordionSummary>
                <AccordionDetails>
                  <Row>
                    <Col lg={12} className="py-2">
                      <Typography
                        fontSize="1rem"
                        fontWeight="300"
                        align="start"
                      >
                        Chronic and Past Health Conditions
                      </Typography>
                      <Typography
                        fontSize="1rem"
                        fontWeight="400"
                        align="start"
                      >
                        {patientData?.chronic || "No"}
                      </Typography>
                    </Col>
                    <Col lg={12} className="py-2">
                      <Typography
                        fontSize="1rem"
                        fontWeight="300"
                        align="start"
                      >
                        Family health history
                      </Typography>
                      <Typography
                        fontSize="1rem"
                        fontWeight="400"
                        align="start"
                      >
                        {patientData?.familyHealthHistory || "No"}
                      </Typography>
                    </Col>

                    <Col lg={12} className="py-2">
                      <Typography
                        fontSize="1rem"
                        fontWeight="300"
                        align="start"
                      >
                        Known allergies
                      </Typography>
                      <Typography
                        fontSize="1rem"
                        fontWeight="400"
                        align="start"
                      >
                        {patientData?.knownAllergies || "No"}
                      </Typography>
                    </Col>
                    <Col lg={12} className="py-2">
                      <Typography
                        fontSize="1rem"
                        fontWeight="300"
                        align="start"
                      >
                        Surgical History
                      </Typography>
                      <Typography
                        fontSize="1rem"
                        fontWeight="400"
                        align="start"
                      >
                        {patientData?.surgicalHistory || "No"}
                      </Typography>
                    </Col>
                  </Row>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="py-2 fs-5 fw-normal"
                >
                  Medications
                </AccordionSummary>
                <AccordionDetails>
                  <Row>
                    <Col lg={12} className="py-2">
                      <Typography
                        fontSize="1rem"
                        fontWeight="300"
                        align="start"
                      >
                        Current medications
                      </Typography>
                      <Typography
                        fontSize="1rem"
                        fontWeight="400"
                        align="start"
                      >
                        {patientData?.currentMedicaton || "No"}
                      </Typography>
                    </Col>
                    <Col lg={12} className="py-2">
                      <Typography
                        fontSize="1rem"
                        fontWeight="300"
                        align="start"
                      >
                        Previously taken medications (last 6 months)
                      </Typography>
                      <Typography
                        fontSize="1rem"
                        fontWeight="400"
                        align="start"
                      >
                        {patientData?.previouslyMedication || "No"}
                      </Typography>
                    </Col>
                  </Row>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="py-2 fs-5 fw-normal"
                >
                  Life Patterns & Habits
                </AccordionSummary>
                <AccordionDetails>
                  <Row>
                    <Col lg={12} className="py-2">
                      <Typography
                        fontSize="1rem"
                        fontWeight="300"
                        align="start"
                      >
                        Daily smoking intensity
                      </Typography>
                      <Typography
                        fontSize="1rem"
                        fontWeight="400"
                        align="start"
                      >
                        {patientData?.dailySmokingIntensity || "N/A"}
                      </Typography>
                    </Col>
                    <Col lg={12} className="py-2">
                      <Typography
                        fontSize="1rem"
                        fontWeight="300"
                        align="start"
                      >
                        Weekly alcohol intake
                      </Typography>
                      <Typography
                        fontSize="1rem"
                        fontWeight="400"
                        align="start"
                      >
                        {patientData?.weeklyAlcoholIntake || "N/A"}
                      </Typography>
                    </Col>

                    <Col lg={12} className="py-2">
                      <Typography
                        fontSize="1rem"
                        fontWeight="300"
                        align="start"
                      >
                        Dietary habits
                      </Typography>
                      <Typography
                        fontSize="1rem"
                        fontWeight="400"
                        align="start"
                      >
                        {patientData?.dietaryHabit || "N/A"}
                      </Typography>
                    </Col>
                    <Col lg={12} className="py-2">
                      <Typography
                        fontSize="1rem"
                        fontWeight="300"
                        align="start"
                      >
                        Weekly physical activity level
                      </Typography>
                      <Typography
                        fontSize="1rem"
                        fontWeight="400"
                        align="start"
                      >
                        {patientData?.weeklyHabit || "N/A"}
                      </Typography>
                    </Col>
                    <Col lg={12} className="py-2">
                      <Typography
                        fontSize="1rem"
                        fontWeight="300"
                        align="start"
                      >
                        Daily sleep pattern
                      </Typography>
                      <Typography
                        fontSize="1rem"
                        fontWeight="400"
                        align="start"
                      >
                        {patientData?.dailySleepPattern || "N/A"}
                      </Typography>
                    </Col>
                    <Col lg={12} className="py-2">
                      <Typography
                        fontSize="1rem"
                        fontWeight="300"
                        align="start"
                      >
                        Stress level
                      </Typography>
                      <Typography
                        fontSize="1rem"
                        fontWeight="400"
                        align="start"
                      >
                        {patientData?.stressLevel || "N/A"}
                      </Typography>
                    </Col>
                  </Row>
                </AccordionDetails>
              </Accordion>
            </Box>
          </div>
        </div>
      )}
    </div>
  );
};

export default HealthProfile;
