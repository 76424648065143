import { Navbar } from "react-bootstrap";
import { IMAGES } from "../../Assets";
import { Link, useNavigate } from "react-router-dom";
import { API_KEYS, ROUTER_PATH } from "../../config";
import { Button, GenericModal, Typography } from "../../Components";
import LogoutIcon from "@mui/icons-material/Logout";
import useWindowSizeHook from "../../Components/WindowSizeHook";
import { useEffect } from "react";
import { useState } from "react";
import { IoClose } from "react-icons/io5";

export default function LayoutNavbar() {
  const navigate = useNavigate();
  const { width } = useWindowSizeHook();
  const [isLogoutModal, setIsLogoutModal] = useState(false);

  const handleLogout = () => {
    handleFacebookLogout();
    sessionStorage.removeItem("pageReloaded");
    localStorage.removeItem("med_user");
    localStorage.removeItem("medistreamline");
    navigate(ROUTER_PATH.SIGN_IN);
    window.location.reload();
  };

  useEffect(() => {
    // Initialize Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: API_KEYS.FACEBOOK_APP_ID,
        cookie: false,
        xfbml: true,
        version: "v9.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const handleFacebookLogout = () => {
    window.FB.logout(function (response) {
      console.log("User signed out from Facebook.");
    });
  };

  return (
    <Navbar className="bg-none w-100 mb-2 d-flex justify-content-between">
      {isLogoutModal && (
        <GenericModal
          show={isLogoutModal}
          onHide={() => setIsLogoutModal(false)}
          className="p-5  "
          centered
          aria-labelledby="modal"
        >
          <div className="p-4  ">
            <div className="d-flex justify-content-end w-100">
              <IoClose
                size={20}
                onClick={() => setIsLogoutModal(false)}
                className="cursor-pointer"
              />
            </div>
            <Typography
              align="center"
              fontSize={width < 500 ? "1rem" : "1.2rem"}
              fontWeight="500"
              className="p-2"
            >
              Are you sure you want to logout?
            </Typography>
            <div className="d-flex justify-content-center gap-3  mt-3">
              <Button
                className="bg-secondary"
                minWidth={width < 500 ? "6rem" : "8rem"}
                height={width < 500 ? "2.5rem" : "3rem"}
                onClick={() => setIsLogoutModal(false)}
              >
                <Typography
                  align="center"
                  fontSize="1rem"
                  fontWeight="500"
                  color="#fff"
                >
                  No
                </Typography>
              </Button>
              <Button
                minWidth={width < 500 ? "6rem" : "8rem"}
                height={width < 500 ? "2.5rem" : "3rem"}
                onClick={() => handleLogout()}
              >
                <Typography
                  align="center"
                  fontSize="1rem"
                  fontWeight="500"
                  color="#fff"
                >
                  Yes
                </Typography>
              </Button>
            </div>
          </div>
        </GenericModal>
      )}

      <Link to={ROUTER_PATH.HOME_PAGE}>
        <img
          src={IMAGES.MEDISTREAMLINE_LOGO}
          width={width < 500 ? "180px" : "250px"}
          className=""
          alt="React Bootstrap logo"
        />
      </Link>
      {width < 768 ? (
        <Button
          backgroundColor="#fff"
          type="submit"
          onClick={() => setIsLogoutModal(true)}
          minWidth="3rem"
          height="2.5rem"
        >
          <LogoutIcon className="text-danger" />
        </Button>
      ) : (
        <Button
          backgroundColor="#fff"
          type="submit"
          onClick={() => setIsLogoutModal(true)}
        >
          <Typography
            align="center"
            fontWeight="500"
            fontSize="1rem"
            color="#9370DB"
          >
            Logout
          </Typography>
        </Button>
      )}
    </Navbar>
  );
}
