import PropTypes from "prop-types";
import { toast } from "react-toastify";

function Toaster({ ...props }) {
  //
  return toast(props.message || "hi im toast", {
    type:
      props.type === "success"
        ? "success"
        : props.type === "error"
        ? "error"
        : "info",
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export default Toaster;

Toaster.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};

Toaster.defaultProps = {
  type: "info",
  message: "Toaster Message Here",
};
