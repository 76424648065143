import * as yup from "yup";

export const PatientProfileValidationSchemas = [
  yup.object().shape({
    age: yup
      .number()
      .typeError("Age must be a number")
      .required("Age is required")
      .positive("Age must be a positive number")
      .integer("Age must be an integer"),
    name: yup.string().required("Full Name is required"),
    occupation: yup.string().required("Occupation is required"),
  
    // .matches(/^[0-9]{11}$/, "Contact number must be exactly 11 digits"),
  }),

  yup.object().shape({
    unitSystem: yup.number(),

    weightLbs: yup.number().when("unitSystem", {
      is: (value) => parseInt(value) === 1,
      then: (schema) =>
        schema
          .typeError("weight must be a number")
          .required("weight is required")
          .positive("weight must be a positive number")
          .min(1, "Value must be at least 1")
          .max(1400, "Value must be at most 1400"),
      otherwise: (schema) => schema.notRequired(),
    }),

    heightFt: yup.number().when("unitSystem", {
      is: (value) => parseInt(value) === 1,
      then: (schema) =>
        schema
          .typeError("height must be a number")
          .required("height is required")
          .min(1, "Value must be at least 1")
          .max(8, "Value must be at most 8"),
      otherwise: (schema) => schema.notRequired(),
    }),

    weightKg: yup.number().when("unitSystem", {
      is: (value) => parseInt(value) === 2,
      then: (schema) =>
        schema
          .typeError("weight must be a number")
          .required("weight is required")
          .positive("weight must be a positive number")
          .min(1, "Value must be at least 1")
          .max(1400, "Value must be at most 1400"),
      otherwise: (schema) => schema.notRequired(),
    }),
    heightCm: yup.number().when("unitSystem", {
      is: (value) => parseInt(value) === 2,
      then: (schema) =>
        schema
          .typeError("height must be a number")
          .required("height is required")
          .min(1, "Value must be at least 1")
          .max(272, "Value must be at most 272"),
      otherwise: (schema) => schema.notRequired(),
    }),
  }),

  yup.object().shape({
    primaryRacialId: yup.number(),
    // primaryRacialId: yup.string().required("This field is required"),
    primaryRacialDetail: yup.string().when("primaryRacialId", {
      is: (value) => value === 1008, // If isChronic is a string
      then: (schema) => schema.required("This field is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  }),
  yup.object().shape({
    // Validation for Gender
  }),
  yup.object().shape({
    isChronic: yup.number(),
    medicalHistory: yup.string().when("isChronic", {
      is: (value) => parseInt(value) === 2, // If isChronic is a string
      then: (schema) => schema.required("This field is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  }),

  yup.object().shape({
    familyHealth: yup.number(),
    familyHealthInput: yup.string().when("familyHealth", {
      is: (value) => parseInt(value) === 2, // If isChronic is a string
      then: (schema) => schema.required("This field is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  }),

  yup.object().shape({
    allergies: yup.number(),
    allergiesInput: yup.string().when("allergies", {
      is: (value) => parseInt(value) === 2, // If isChronic is a string
      then: (schema) => schema.required("This field is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  }),

  yup.object().shape({
    surgical: yup.number(),
    surgicalInput: yup.string().when("surgical", {
      is: (value) => parseInt(value) === 2, // If isChronic is a string
      then: (schema) => schema.required("This field is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  }),

  yup.object().shape({
    currentMedication: yup.number(),
    currentMedicationInput: yup.string().when("currentMedication", {
      is: (value) => parseInt(value) === 2, // If isChronic is a string
      then: (schema) => schema.required("This field is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  }),

  yup.object().shape({
    previousMedication: yup.number(),
    previousMedicationInput: yup.string().when("previousMedication", {
      is: (value) => parseInt(value) === 2, // If isChronic is a string
      then: (schema) => schema.required("This field is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  }),
  yup.object().shape({
    // Validation for Gender
  }),
  yup.object().shape({
    // Validation for Gender
  }),
  yup.object().shape({
    dietary: yup.number(),
    dietaryInput: yup.string().when("dietary", {
      is: (value) => parseInt(value) === 4, // If isChronic is a string
      then: (schema) => schema.required("This field is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  }),
  yup.object().shape({
    // Validation for Gender
  }),
  yup.object().shape({
    // Validation for Gender
  }),
  yup.object().shape({}),
  // yup.object().shape({
  //   bloodPressure: yup.string().required("This field is required"),
  //   pulseRate: yup.string().required("This field required"),
  //   temperature: yup.string().required("This field is required"),
  //   glucoLevel: yup.string().required(" This field is required"),
  //   respiratory: yup.string().required("This field is required"),
  // }),
];

export const ProfileInitialValues = {
  name: "",
  mobileContact: "",
  age: "",
  occupation: "",
  address: "",
  ageUnit: "years",
  unitSystem: 1,
  weightLbs: "",
  heightFt: "",
  weightKg: "",
  heightCm: "",
  medicalHistory: "",
  isChronic: 1,
  familyHealth: 1,
  familyHealthInput: "",
  allergies: 1,
  allergiesInput: "",
  surgical: 1,
  surgicalInput: "",
  currentMedication: 1,
  currentMedicationInput: "",
  previousMedication: 1,
  previousMedicationInput: "",
  dietary: 601,
  dietaryInput: "",
  primaryRacialId: 1001,
  primaryRacialDetail: "",
  sexId: 101,
  smokingId: 401,
  alcoholId: 501,
  weeklyHabit: 701,
  sleepId: 801,
  stressId: 901,
  bloodPressure: "",
  pulseRate: "",
  temperature: "",
  glucoLevel: "",
  respiratory: "",
  menstrualCycle:1101,
};
