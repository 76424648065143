import { Route, Routes } from "react-router-dom";

import { ROUTER_PATH } from "../../config";
import PrivateRoutes from "./PrivateRoutes";
import Layout from "../../Layout";
import { SignIn, Signup } from "../../Pages/Auth";
import Home from "../../Pages/Home";
import Dashboard from "../../Pages/Dashboard/index";
import DashboardHome from "../../Pages/Dashboard/DashboardHome/index";
import DashboardDoctors from "../../Pages/Dashboard/FindADoctor/index";
import DashboardAppointments from "../../Pages/Dashboard/MyAppointments/index";

import PatientDiagnosis from "../../Pages/PatientDiagnosis";
import ThankYou from "../../Pages/ThankYou";
import VitalsMonitoring from "../../Pages/VitalsMonitoring";
import ChatCard from "../../Pages/StartChat/StartChatCard";
import UnityFile from "../../Pages/Auth/unity";
import AuthLayout from "../../Layout/authLayout";
import Subscription from "../../Pages/Subscription";
import Chat from "../../Pages/PatientDiagnosis/chat";
import ChatHistory from "../../Pages/ChatHistory";
// import IndividualSession from "../../Pages/ChatHistory/individualSession";
// import Report from "../../Pages/ChatHistory/report";
import ChatDetails from "../../Pages/ChatHistory/chatDetails";
import Welcome from "../../Pages/Steps/welcome";
import PrimaryGoal from "../../Pages/Steps/primaryGoal";
import HealthDetails from "../../Pages/Steps/healthDetails";
import HealthProfile from "../../Pages/HealthProfile";
import PageNotFound from "../../Pages/PageNotFound";
import PublicRoutes from "./PublicRoutes";
import DashboardRoutes from "./DashboardRoutes";
import ForgotPassword from "../../Pages/Auth/ForgotPassword";
import VerifyOTP from "../../Pages/Auth/VerifyOTP";
import ResetPassword from "../../Pages/Auth/ResetPassword";
import SessionExpired from "../../Pages/Auth/SessionExpired";

export default function AllRoutes() {
  return (
    <Routes>
      {/* --------------------Dashboard Routes-------------------------- */}
      <Route element={<DashboardRoutes />}>
        <Route path={ROUTER_PATH.Dashboard} element={<Dashboard />}>
          <Route index element={<DashboardHome />} />

          <Route
            path={ROUTER_PATH.Dashboard_Find_Doctor}
            element={<DashboardDoctors />}
          />
          <Route
            path={ROUTER_PATH.Dashboard_My_Appointments}
            element={<DashboardAppointments />}
          />
        </Route>
      </Route>

      {/* Public Routes */}
      <Route element={<PublicRoutes />}>
        <Route element={<AuthLayout />}>
          <Route path={ROUTER_PATH.SIGN_IN} element={<SignIn />} />
          <Route path={ROUTER_PATH.SIGN_UP} element={<Signup />} />
          <Route path={ROUTER_PATH.WELCOME} element={<Welcome />} />
          <Route path={ROUTER_PATH.PRIMARY_GOAL} element={<PrimaryGoal />} />
          <Route
            path={ROUTER_PATH.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
          <Route path={ROUTER_PATH.VERIFY_OTP} element={<VerifyOTP />} />
          <Route
            path={ROUTER_PATH.RESET_PASSWORD}
            element={<ResetPassword />}
          />
          <Route
            path={ROUTER_PATH.HEALTH_DETAILS}
            element={<HealthDetails />}
          />
          <Route path={"/unity"} element={<UnityFile />} />
        </Route>
      </Route>

      {/*----------------------Private Routes------------------------- */}
      <Route element={<Layout />}>
        {/* Private Routes */}
        <Route element={<PrivateRoutes />}>
          <Route path={ROUTER_PATH.HOME_PAGE} element={<Home />} />

          <Route
            path={ROUTER_PATH.UPDATE_HEALTH_PROFILE}
            element={<HealthDetails />}
          />
          <Route
            path={ROUTER_PATH.VITALS_MONITORING}
            element={<VitalsMonitoring />}
          />
          <Route path={ROUTER_PATH.START_CHAT} element={<ChatCard />} />
          <Route
            path={ROUTER_PATH.PATIENT_DIAGNOSIS}
            element={<PatientDiagnosis />}
          />
          <Route path={ROUTER_PATH.CHAT} element={<Chat />} />
          <Route path={ROUTER_PATH.CHAT_HISTORY} element={<ChatHistory />} />
          <Route path={ROUTER_PATH.CHAT_DETAILS} element={<ChatDetails />} />
          <Route
            path={ROUTER_PATH.HEALTH_PROFILE}
            element={<HealthProfile />}
          />

          {/* <Route
            path={ROUTER_PATH.INDIVIDUAL_SESSION}
            element={<IndividualSession />}
          />
          <Route path={ROUTER_PATH.REPORT} element={<Report />} /> */}
          <Route path={ROUTER_PATH.SUBSCRIPTION} element={<Subscription />} />
          <Route path={ROUTER_PATH.THANK_YOU} element={<ThankYou />} />
        </Route>
      </Route>
      <Route path="*" element={<PageNotFound />} />
      <Route path={ROUTER_PATH.SESSION_EXPIRE} element={<SessionExpired />} />
    </Routes>
  );
}
