import PropTypes from "prop-types";

export default function Button({ children, ...props }) {
  const buttonStyles = {
    minWidth: props.minWidth,
    height: props.height,
    backgroundColor: props.backgroundColor,
    border: props.border,
    borderRadius: props.borderRadius,
    // background: props.background,
  };
  return (
    <button {...props} style={buttonStyles} className={`${props.className}`}>
      {children}
    </button>
  );
}

Button.propTypes = {
  minWidth: PropTypes.string,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  borderRadius: PropTypes.string,
  onClick: PropTypes.func,
  hasIcon: PropTypes.bool,
  isIconLeft: PropTypes.bool,
  isIconRight: PropTypes.bool,
  className: PropTypes.string,
};

Button.defaultProps = {
  minWidth: "10rem",
  height: "3rem",
  backgroundColor: "#9370DB",
  border: "none",
  borderRadius: "6px",
  onClick: () => {},
  hasIcon: false,
  isIconLeft: false,
  isIconRight: false,
  className: "",
};
