import { Box, Typography } from "../../../../Components";
import { Form } from "react-bootstrap";

const KnownAllergies = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const options = [
    {
      id: 1,
      label: "No",
    },
    {
      id: 2,
      label: "Yes",
    },
  ];
  return (
    <div>
      {" "}
      <Typography fontSize="1rem" fontWeight="400" color="#9370DB">
        MEDICAL HISTORY
      </Typography>
      <Typography fontSize="1.25rem" fontWeight="500" className="my-3">
        Known Allergies
      </Typography>
      {options?.map((option, idx) => (
        <>
          <Box
            className="p-3 my-3 cursor-pointer option-wrapper"
            onClick={() => {
              setFieldValue("allergies", option?.id);
            }}
            height="100%"
            width="100%"
            borderRadius="0.5rem"
            background={values?.allergies === option?.id && "#9370DB"}
            border={values?.allergies === option?.id && "1px solid #9370DB"}
          >
            <Typography
              fontSize=".9rem"
              fontWeight={values?.allergies === option?.id && "500"}
              className="heading"
              color={values?.allergies === option?.id && "white"}
            >
              {option?.label}
            </Typography>
          </Box>
          {values?.allergies === 2 && option?.id === 2 && (
            <>
              <Form.Control
                type="text"
                backgroundColor="#EBEBFF"
                name="allergiesInput"
                placeholder={"e.g.; Peanut Allergy etc"}
                className="w-100 fs-6"
                value={values?.allergiesInput}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.allergiesInput && !!errors.allergiesInput}
                size="lg"
                autoFocus
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.allergiesInput}
              </Form.Control.Feedback>
            </>
          )}
        </>
      ))}
    </div>
  );
};

export default KnownAllergies;
