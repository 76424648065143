// src/Pages/PageNotFound/index.js
import React from "react";
import { Box } from "../Components";
import { ROUTER_PATH } from "../config";
import { Link, useLocation } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { IMAGES } from "../Assets";

const PageNotFound = () => {
  const location = useLocation();

  return (
    <div
      className={"main-container"}
      style={{
        background:
          location.pathname === ROUTER_PATH.SIGN_IN ? "white" : "none",
      }}
    >
      {/* <video src={bgVideo} autoPlay loop muted className="bg-video" /> */}
      <img src={IMAGES.MINI_BACKGROUND} alt="background" className="bg-video" />

      <Navbar className="bg-none w-100 mb-2 d-flex justify-content-between">
        <Link to={ROUTER_PATH.HOME_PAGE}>
          <img
            src={IMAGES.MEDISTREAMLINE_LOGO}
            width="250px"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Link>
      </Navbar>
      <Box
        width="100%"
        maxWidth="202rem"
        height="70vh"
        borderRadius="1.375rem"
        background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
        shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
        backdropFilter="blur(15px)"
        className="p-5 d-flex flex-colum align-items-center justify-content-center"
      >
        <div className="display-4 fw-bolder text-primary">
          404 - Page Not Found
        </div>
      </Box>
    </div>
  );
};

export default PageNotFound;
