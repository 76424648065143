import React, { useState } from "react";
import {
  Button,
  GenericLoader,
  InputField,
  Typography,
} from "../../../Components";
import * as Yup from "yup";
import { Col, Row } from "react-bootstrap";
import { InputAdornment } from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTER_PATH } from "../../../config";
import { useFormik } from "formik";
import { sendOTPAction, verifyOTPAction } from "../../../Redux/actions";
import Timer from "./Timer";

function VerifyOTP() {
  const user = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [expiryTime, setExpiryTime] = useState(location?.state?.expiryTime);
  const OTP_Schema = Yup.object().shape({
    otp: Yup.string()
      .matches(/^\d{6}$/, "OTP must be exactly 6 digits")
      .required("Please enter the OTP"),
  });

  const moveToNext = (response) => {
    response?.data?.succeeded &&
      navigate(ROUTER_PATH.RESET_PASSWORD, { state: location?.state.email });
    // : navigate(ROUTER_PATH.FORGOT_PASSWORD);
  };
  const retainHere = (response) => {
    setExpiryTime(response?.data?.data);
    // : navigate(ROUTER_PATH.FORGOT_PASSWORD);
  };

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: OTP_Schema,
    onSubmit: (values) => {
      dispatch(
        verifyOTPAction(
          {
            email: location?.state?.email,
            otp: values.otp ? values.otp.toString() : "",
            timezone: Intl.DateTimeFormat()?.resolvedOptions()?.timeZone,
          },
          moveToNext
        )
      );
    },
  });

  const handleResendOTP = () => {
    dispatch(
      sendOTPAction(
        {
          email: location?.state?.email,
          isResend: true,
        },
        retainHere
      )
    );
  };

  return (
    <div>
      <div className={`h-screen`}>
        <Row className="m-0 p-5 vh-100  w-100 align-items-center justify-content-end">
          <Col
            md={12}
            lg={4}
            className="m-0 p-0 d-flex align-items-start flex-column"
          >
            <h1 className="text-white  font-poppins-bold">Verify OTP</h1>
            <p className=" text-white font-poppins-semibold mb-0">
              Enter your OTP to verify your Email
            </p>

            <form
              onSubmit={formik.handleSubmit}
              className="container-fluid rounded-1 p-0"
            >
              <Row className="m-0 p-0">
                <Col md={12} className="mt-4 p-0">
                  <InputField
                    type="number"
                    backgroundColor="#EBEBFF"
                    name="otp"
                    placeholder="Type your OTP"
                    className="w-100"
                    onChange={formik.handleChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <HowToRegIcon size={20} className="text-light-gray" />
                      </InputAdornment>
                    }
                  />
                  {formik.touched.otp && formik.errors.otp && (
                    <small className="text-danger small">
                      {formik.errors.otp}
                    </small>
                  )}
                </Col>

                <Col md={12} className="m-0 p-0 mt-4">
                  <Button type="submit" className="w-100 signup-button">
                    <Typography
                      fontWeight="500"
                      align="center"
                      fontSize="1rem"
                      color="#fff"
                    >
                      {user?.VERIFY_OTP_IS_LOADING ||
                      user?.SEND_OTP_IS_LOADING ? (
                        <GenericLoader />
                      ) : (
                        "Verify OTP"
                      )}
                    </Typography>
                  </Button>
                  <Timer
                    expiryTime={expiryTime}
                    handleResendOTP={handleResendOTP}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default VerifyOTP;
