const INITIAL_STATES = {
  GET_LOOKUP_LIST_LOADING: false,
  GET_LOOKUP_LIST_SUCCESS: false,
  GET_LOOKUP_LIST_FAILURE: false,
  GET_LOOKUP_LIST_ERROR: null,
  GET_LOOKUP_LIST: null,

  CREATE_PATIENT_ASSESSMENT_IS_LOADING: false,
  CREATE_PATIENT_ASSESSMENT_IS_SUCCESS: false,
  CREATE_PATIENT_ASSESSMENT_IS_FAILURE: false,
  PATIENT_ASSESSMENT_DATA: null,
  CREATE_PATIENT_ASSESSMENT_IS_ERROR: null,

  GET_PATIENT_ASSESSMENT_ID_LOADING: false,
  GET_PATIENT_ASSESSMENT_ID_IS_SUCCESS: false,
  GET_PATIENT_ASSESSMENT_ID_IS_FAILURE: false,
  GET_PATIENT_ASSESSMENT_ID_DATA: [],
  GET_PATIENT_ASSESSMENT_ID_IS_ERROR: null,
  PATIENT_ASSESSMENT_ID: null,

  UPDATE_PATIENT_ASSESSMENT_LOADING: true,
  UPDATE_PATIENT_ASSESSMENT_IS_SUCCESS: false,
  UPDATE_PATIENT_ASSESSMENT_IS_FAILURE: false,
  UPDATE_PATIENT_ASSESSMENT_DATA: null,
  UPDATE_PATIENT_ASSESSMENT_IS_ERROR: null,
};

export function patientProfileReducer(state = INITIAL_STATES, action) {
  switch (action.type) {
    case "GET_LOOKUP_LIST_REQUEST":
      return {
        ...state,
        GET_LOOKUP_LIST_LOADING: true,
        GET_LOOKUP_LIST_SUCCESS: false,
        GET_LOOKUP_LIST_FAILURE: false,
        GET_LOOKUP_LIST_ERROR: null,
      };
    case "GET_LOOKUP_LIST_SUCCESS":
      return {
        ...state,
        GET_LOOKUP_LIST_LOADING: false,
        GET_LOOKUP_LIST_SUCCESS: true,
        GET_LOOKUP_LIST_FAILURE: false,
        GET_LOOKUP_LIST_ERROR: null,
        GET_LOOKUP_LIST: action.payload,
      };
    case "GET_LOOKUP_LIST_FAILURE":
      return {
        ...state,
        GET_LOOKUP_LIST_LOADING: false,
        GET_LOOKUP_LIST_SUCCESS: false,
        GET_LOOKUP_LIST_FAILURE: true,
        GET_LOOKUP_LIST_ERROR: action.payload,
      };

    case "CREATE_PATIENT_ASSESSMENT_REQUEST":
      return {
        ...state,
        CREATE_PATIENT_ASSESSMENT_IS_LOADING: true,
        CREATE_PATIENT_ASSESSMENT_IS_SUCCESS: false,
        CREATE_PATIENT_ASSESSMENT_IS_FAILURE: false,
        PATIENT_ASSESSMENT_DATA: null,
        CREATE_PATIENT_ASSESSMENT_IS_ERROR: null,
      };
    case "CREATE_PATIENT_ASSESSMENT_SUCCESS":
      return {
        ...state,
        CREATE_PATIENT_ASSESSMENT_IS_LOADING: false,
        CREATE_PATIENT_ASSESSMENT_IS_SUCCESS: true,
        CREATE_PATIENT_ASSESSMENT_IS_FAILURE: false,
        PATIENT_ASSESSMENT_DATA: action.payload,
        CREATE_PATIENT_ASSESSMENT_IS_ERROR: null,
      };
    case "CREATE_PATIENT_ASSESSMENT_ERROR":
      return {
        ...state,
        CREATE_PATIENT_ASSESSMENT_IS_LOADING: false,
        CREATE_PATIENT_ASSESSMENT_IS_SUCCESS: false,
        CREATE_PATIENT_ASSESSMENT_IS_FAILURE: true,
        PATIENT_ASSESSMENT_DATA: null,
        CREATE_PATIENT_ASSESSMENT_IS_ERROR: action.payload,
      };

    case "GET_PATIENT_ASSESSMENT_ID_REQUEST":
      return {
        ...state,
        GET_PATIENT_ASSESSMENT_ID_LOADING: true,
        GET_PATIENT_ASSESSMENT_ID_IS_SUCCESS: false,
        GET_PATIENT_ASSESSMENT_ID_IS_FAILURE: false,
        GET_PATIENT_ASSESSMENT_ID_DATA: null,
        GET_PATIENT_ASSESSMENT_ID_IS_ERROR: null,
        PATIENT_ASSESSMENT_ID: null,
      };
    case "GET_PATIENT_ASSESSMENT_ID_SUCCESS":
      return {
        ...state,
        GET_PATIENT_ASSESSMENT_ID_LOADING: false,
        GET_PATIENT_ASSESSMENT_ID_IS_SUCCESS: true,
        GET_PATIENT_ASSESSMENT_ID_IS_FAILURE: false,
        GET_PATIENT_ASSESSMENT_ID_DATA: action.payload,
        PATIENT_ASSESSMENT_ID: action.payload.user_id,
        GET_PATIENT_ASSESSMENT_ID_IS_ERROR: null,
      };
    case "GET_PATIENT_ASSESSMENT_ID_FAILURE":
      return {
        ...state,
        GET_PATIENT_ASSESSMENT_ID_LOADING: false,
        GET_PATIENT_ASSESSMENT_ID_IS_SUCCESS: false,
        GET_PATIENT_ASSESSMENT_ID_IS_FAILURE: true,
        GET_PATIENT_ASSESSMENT_ID_DATA: null,
        GET_PATIENT_ASSESSMENT_ID_IS_ERROR: action.payload.message,
        PATIENT_ASSESSMENT_ID: null,
      };

    case "UPDATE_PATIENT_ASSESSMENT_REQUEST":
      return {
        ...state,
        UPDATE_PATIENT_ASSESSMENT_LOADING: true,
        UPDATE_PATIENT_ASSESSMENT_IS_SUCCESS: false,
        UPDATE_PATIENT_ASSESSMENT_IS_FAILURE: false,
        UPDATE_PATIENT_ASSESSMENT_DATA: null,
        UPDATE_PATIENT_ASSESSMENT_IS_ERROR: null,
      };
    case "UPDATE_PATIENT_ASSESSMENT_SUCCESS":
      return {
        ...state,
        UPDATE_PATIENT_ASSESSMENT_LOADING: false,
        UPDATE_PATIENT_ASSESSMENT_IS_SUCCESS: true,
        UPDATE_PATIENT_ASSESSMENT_IS_FAILURE: false,
        UPDATE_PATIENT_ASSESSMENT_DATA: action.payload,
        UPDATE_PATIENT_ASSESSMENT_IS_ERROR: null,
      };
    case "UPDATE_PATIENT_ASSESSMENT_ERROR":
      return {
        ...state,
        UPDATE_PATIENT_ASSESSMENT_LOADING: false,
        UPDATE_PATIENT_ASSESSMENT_IS_SUCCESS: false,
        UPDATE_PATIENT_ASSESSMENT_IS_FAILURE: true,
        UPDATE_PATIENT_ASSESSMENT_DATA: null,
        UPDATE_PATIENT_ASSESSMENT_IS_ERROR: action.payload.message,
      };

    default:
      return state;
  }
}
