import { getAdminDashboardCards, getAdminDashboardPatientGraphApi, getAdminDashboardSubscriptionGraphApi } from "../api/dashboard";

export function getAdminDashboardStats() {
    return (dispatch) => {
      dispatch({
        type: "GET_DASHBOARD_CARDS_STATS_LIST_REQUEST",
      });
      getAdminDashboardCards().then(
        (response) => {
          if (response.status === 200) {
            dispatch({
              type: "GET_DASHBOARD_CARDS_STATS_LIST_SUCCESS",
              payload: response.data.data,
            });
          
            if (!response?.data?.data) {
              dispatch({
                type: "GET_DASHBOARD_CARDS_STATS_LIST_FAILURE",
                payload: response?.data?.data,
              });
            }
          }
        },
        (error) => {
          dispatch({
            type: "GET_DASHBOARD_CARDS_STATS_LIST_FAILURE",
            payload: error.message,
          });
        }
      );
    };
  }
export function getAdminDashboardPatientRegistrationGraph(payload) {
    return (dispatch) => {
      dispatch({
        type: "GET_DASHBOARD_PATIENT_GRAPH_LIST_REQUEST",
      });
      getAdminDashboardPatientGraphApi(payload).then(
        (response) => {
          if (response.status === 200) {
            dispatch({
              type: "GET_DASHBOARD_PATIENT_GRAPH_LIST_SUCCESS",
              payload: response.data.data,
            });
          
            if (!response?.data?.data) {
              dispatch({
                type: "GET_DASHBOARD_PATIENT_GRAPH_LIST_FAILURE",
                payload: response?.data?.data,
              });
            }
          }
        },
        (error) => {
          dispatch({
            type: "GET_DASHBOARD_PATIENT_GRAPH_LIST_FAILURE",
            payload: error.message,
          });
        }
      );
    };
  }
export function getAdminDashboardSubscriptionGraph(durationType, plan) {
  
    return (dispatch) => {
      dispatch({
        type: "GET_DASHBOARD_SUBSCRIPTION_LIST_REQUEST",
      });
      getAdminDashboardSubscriptionGraphApi(durationType, plan).then(
        (response) => {
          if (response.status === 200) {
            dispatch({
              type: "GET_DASHBOARD_SUBSCRIPTION_LIST_SUCCESS",
              payload: response.data.data,
            });
          
            if (!response?.data?.data) {
              dispatch({
                type: "GET_DASHBOARD_SUBSCRIPTION_LIST_FAILURE",
                payload: response?.data?.data,
              });
            }
          }
        },
        (error) => {
          dispatch({
            type: "GET_DASHBOARD_SUBSCRIPTION_LIST_FAILURE",
            payload: error.message,
          });
        }
      );
    };
  }