import axios from "axios";
import { API_URLS} from "../../config";

function getBloodPressure(){
  return axios.get(
    `${API_URLS.VITALS_MONITORING.GET_BLOOD_PRESSURE}`
  )
}

function getBloodOxygen(){
  return axios.get(
    `${API_URLS.VITALS_MONITORING.GET_BLOOD_OXYGEN}`
  )
}

function getPulseRate(){
  return axios.get(
    `${API_URLS.VITALS_MONITORING.GET_PULSE_RATE}`
  )
}

function getTemperature(){
  return axios.get(
    `${API_URLS.VITALS_MONITORING.GET_TEMPERATURE}`
  )
}

function getGlucose(){
  return axios.get(
    `${API_URLS.VITALS_MONITORING.GET_GLUCOSE}`
  )
}

function getWeight(){
  return axios.get(
    `${API_URLS.VITALS_MONITORING.GET_WEIGHT}`
  )
}

export {
  getBloodPressure,
  getBloodOxygen,
  getGlucose,
  getPulseRate,
  getTemperature,
  getWeight,
}