/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsFillMicMuteFill, BsFillStopFill } from "react-icons/bs";
import { useNavigate } from "react-router";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Loader from "react-dots-loader";
import { FaCircleArrowUp } from "react-icons/fa6";

import {
  Box,
  Button,
  ConfirmationModal,
  GenericInput,
  GenericLoader,
  GenericModal,
  Toaster,
  Typography,
} from "../../Components";
import {
  endSessionAction,
  generateChatNameAction,
  getChatByUsers,
  getDoctorProfile,
  getPromptResponse,
  getUserChatHistory,
  getWelcomeMessage,
  saveChatAction,
  saveMessage,
  saveUserChat,
} from "../../Redux/actions";
import { BASE_URL, ROUTER_PATH } from "../../config";
import ReplyTemplate from "./components/ReplyTemplate";
import MessageTemplate from "./components/MessageTemplate";
import { Col, Form, Row } from "react-bootstrap";
import { IMAGES } from "../../Assets";
import ReactPlayer from "react-player";
import { useLocation } from "react-router-dom";
// import { Unity, useUnityContext } from "react-unity-webgl";
import axios from "axios";
import { getAudioFromTextApi } from "../../Redux/api/chat";
import useWindowSizeHook from "../../Components/WindowSizeHook";
import { getLoggedInUser } from "../../utils/helper";
import UpgradeModal from "./components/UpgradeModal";
import { FaPowerOff } from "react-icons/fa";
import { uploadMedia } from "../../Redux/api/blobUpload";
// import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import { getSingleImageCountAction } from "../../Redux/actions/messageActions";

export default function PatientDiagnosis() {
  const chat = useSelector((state) => state.chat);

  console.log("chatvalue", chat);

  const playerRef = useRef();
  const USER_ID = localStorage.getItem("userId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const medUser = getLoggedInUser();

  const [file, setFile] = useState();
  const [previewImg, setPreviewImg] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const inputRef = useRef(null);
  const [inActiveModal, setInActiveModal] = useState(false);
  const { state } = useLocation();
  const [textareaValue, setTextareaValue] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imageUploadingLoading, setImageUploadLoading] = useState(false);
  const [chatArray, setChatArray] = useState([]);
  const [userPrompt, setUserPrompt] = useState([]);
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const [chatName, setChatName] = useState();
  const [audioBlob, setAudioBlob] = useState(null);
  const [playPause] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);
  const [speechToTextResponse, setSpeechToTextResponse] = useState(null);
  const [totalMessageCount, setTotalMessageCount] = useState(null);
  const [spokenMessages, setSpokenMessages] = useState([]);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [startListening, setStartListening] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [imageCount, setImageCount] = useState(0);
  const [voiceLoader, setVoiceLoader] = useState(null);

  console.log(
    "chatId",
    chatId,
    "speechToTextResponse",
    speechToTextResponse,
    "setUploadedImage",
    setUploadedImage
  );
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setInActiveModal(true);
    }, 600000);
  };
  const handleUserActivity = (event) => {
    if (!inActiveModal) {
      resetTimeout();
    }
  };
  useEffect(() => {
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("click", handleUserActivity);

    resetTimeout();

    return () => {
      clearTimeout(timeoutRef.current);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
    };
  }, [inActiveModal]);

  useEffect(() => {
    if (!medUser?.currentPlanId) {
      localStorage.removeItem("med_user");
      window.location.reload();
    }
  }, []);

  // function startInActiveTimer() {
  //   inActiveTimer.current = setTimeout(() => {
  //     clearTimeout(inActiveTimer.current);
  //     setInActiveModal(true);
  //     // }, 10000);
  //   }, 600000);
  // }

  console.log("chatArrayw", chat);

  // function resetInActiveTimer() {
  //   clearTimeout(inActiveTimer.current);
  //   startInActiveTimer();
  // }

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
    // resetInActiveTimer();
  };

  const updateUserPrompt = () => {
    const existingMessageIds = new Set(
      userPrompt.map((message) => message.messageId)
    );

    const newMessages = chat.CHAT[0].messages.filter(
      (message) =>
        message.fromUser !== "Robot Doctor" &&
        !existingMessageIds.has(message.messageId)
    );

    setUserPrompt([...userPrompt, ...newMessages]);
  };

  useEffect(() => {
    if (!chat?.DOCTOR_PROFILE?.data[0]?.doctorId)
      dispatch(getDoctorProfile({ isSystemDoctor: true }));
  }, [chat?.DOCTOR_PROFILE?.data, dispatch]);

  useEffect(() => {
    if (medUser?.userId && chat?.DOCTOR_PROFILE?.data[0]?.doctorId) {
      dispatch(
        getChatByUsers(
          {
            pageNo: 1,
            size: 20,
            search: null,
            isPagination: true,
            sortBy: null,
            sortDirection: null,
            fromUserId: medUser?.userId,
            toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
            isSessionEnd: false,
            isTitleMessage: false,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          getImageCount
        )
      );
    }
  }, [chat?.DOCTOR_PROFILE?.data, medUser?.userId]);

  const moveToNextChatReplySave = (response) => {
    setSpeechToTextResponse(null);
    setChatId(response?.chatId);
    dispatch(
      saveMessage({
        fromUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
        toUserId: medUser?.userId,
        chatId: response?.chatId,
        isFile: false,
        description: response?.reply,
        isFirstMessage: false,
        planId: medUser?.currentPlanId,
      })
    );
  };

  const saveChatMoveToNext = (response, reply, userText) => {
    setChatId(response?.data?.data);
    dispatch(
      saveMessage(
        {
          fromUserId: medUser?.userId,
          toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
          chatId: response?.data?.data,
          isFile: false,
          description: textareaValue !== "" ? textareaValue : userText,
          planId: medUser?.currentPlanId,
          isFirstMessage: userPrompt?.length === 0 ? true : false,
        },
        moveToNextChatReplySave,
        reply
      )
    );
    setTextareaValue("");
  };

  const chatMoveToNext = (reply, userText) => {
    // console.log(
    //   "chat?.CHAT?.length",
    //   chat?.CHAT?.length,
    //   chat?.DOCTOR_PROFILE,
    //   chat?.DOCTOR_PROFILE?.data?.[0]?.doctorId
    // );
    // if (!chat?.CHAT?.[0]?.chatId) {
    //   dispatch(
    //     getChatByUsers({
    //       pageNo: 1,
    //       size: 20,
    //       search: null,
    //       isPagination: true,
    //       sortBy: null,
    //       sortDirection: null,
    //       fromUserId: medUser?.userId,
    //       toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
    //       isSessionEnd: false,
    // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    //     })
    //   );
    // }

    if (chat?.CHAT?.length === 0 && !(chat?.MESSAGE?.[0]?.chatId || chatId)) {
      dispatch(
        saveChatAction(
          {
            name: "",
            fromUserId: medUser?.userId,
            toUserId: chat?.DOCTOR_PROFILE?.data?.[0]?.doctorId,
            roleId: state?.gender === "male" ? 101 : 102,
            language: state?.language,
          },
          saveChatMoveToNext,
          reply,
          userText
        )
      );
    } else {
      dispatch(
        saveMessage(
          {
            fromUserId: medUser?.userId,
            toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
            chatId:
              chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId || null,
            description: textareaValue !== "" ? textareaValue : userText,
            isFirstMessage: userPrompt?.length === 0 ? true : false,
            planId: medUser?.currentPlanId,
            // isFirstMessage:
            //   !chat?.CHAT?.[0] || !chat?.MESSAGE?.[0] ? true : false,
          },
          moveToNextChatReplySave,
          reply
        )
      );
    }
  };
  // console.log("chat?.CHAT", chat);

  const sendPrompt = (messageValue) => {
    // resetInActiveTimer();
    //
    const OptionalPayload = {
      name: medUser?.name,
      age: medUser?.age,
      gender: medUser?.gender,
      patientId: medUser?.userId,
      occupation: medUser?.occupation,
      language_code: state?.language,
      firstMessage: userPrompt?.length === 0 ? true : false,
    };

    const prompt = {
      id: Date.now() + Math.random(),
      type: `${file ? "imagePrompt" : "prompt"}`,
      message: messageValue,
      file: previewImg,
    };
    setChatArray([...chatArray, prompt]);
    setUserPrompt([...userPrompt, prompt]);
    setTotalMessageCount((prev) => prev + 1);
    setPreviewImg(null);
    setTextareaValue("");
    dispatch(
      getPromptResponse(
        messageValue,
        OptionalPayload,
        chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId || null,
        state?.gender === "male" ? 0 : 1,
        file,
        chatMoveToNext
      )
    );
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    // Prevent sending the message if loading is true
    if (chat?.GET_PROMPT_RESPONSE_IS_LOADING) return;

    const simulatedEvent = { key: "Enter", preventDefault: e.preventDefault };
    handleKeyPress(simulatedEvent);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        // Prevent the default action of the Enter key
        e.preventDefault();
        // Add a new line to the textarea value
        setTextareaValue((prevValue) => prevValue + "\n");
      } else {
        // Prevent sending the message if loading is true
        if (chat?.GET_PROMPT_RESPONSE_IS_LOADING) return;

        e.preventDefault();

        const trimmedValue = textareaValue.trim();

        if (trimmedValue !== "") {
          const maxMessagesPlan1 = 10;
          const maxMessagesPlan2and4 = 100;
          // const maxImagesPlan2and4 = 3;

          if (medUser?.planId === 1) {
            if (
              userPrompt?.length >= 9 ||
              (userPrompt?.length >= 9 && imageCount >= 1) ||
              totalMessageCount >= maxMessagesPlan1
            ) {
              setIsVisible(true);
            } else {
              sendPrompt(trimmedValue);
            }
          } else if (medUser?.planId === 2 || medUser?.planId === 4) {
            // Update condition according to the given by Zaid Khan and Musab. 
            // As totalMessages >= 100 or messages + images >= 100 then we must open upgrade modal
            // Before it was opening modal whenever images are completed.
            if (
              totalMessageCount >= maxMessagesPlan2and4 ||
              ((totalMessageCount + imageCount) >= maxMessagesPlan2and4)
            ) {
              setIsVisible(true);
            } else {
              sendPrompt(trimmedValue);

            }
          } else {
            sendPrompt(trimmedValue); // For other plans or unrestricted users
          }

          setTextareaValue("");
        }
      }
    }
  };

  const handleSaveChat = () => {
    dispatch(saveUserChat(chatName, USER_ID, Toaster, moveToNext));
  };

  const moveToNext = () => {
    setConfirmationModalShow(false);
    dispatch(getUserChatHistory(USER_ID));
    setChatArray([]);
    setUserPrompt([]);
  };

  const scrollFunction = () => {
    const scroller = document.querySelector("#chatBox");
    scroller.scroll(0, 10000);
  };

  useEffect(() => {
    if (state?.gender === undefined) {
      navigate(ROUTER_PATH.HOME_PAGE);
    }
    scrollFunction();
  }, [chatArray]);

  useEffect(() => {
    // if (state?.gender !== null && unityLoaded) {
    if (state?.gender !== null) {
      dispatch(getWelcomeMessage(state?.language));
      // dispatch(getWelcomeMessage({ id: state?.gender === "male" ? 0 : 1 }));
    }
    // }, [dispatch, state, unityLoaded]);
  }, [dispatch, state]);

  const getAudio = async (text, id, messageType) => {
    // dispatch(getAudioFromText());
    let sendData = {
      text: text,
      id: id,
      language_code: state?.language,
    };
    if (messageType !== "imagePrompt" && text !== undefined) {
      const response = await getAudioFromTextApi(sendData);
      const audioDataUrl = `data:audio/mp3;base64,${response.data}`;
      playPause ? setVideoPlaying(false) : setVideoPlaying(true);
      // resetInActiveTimer();
      setAudioBlob(audioDataUrl);
    }
  };

  //   if (audioBlob) {
  //     setVideoPlaying(true);
  //   }

  //   return () => {
  //     setVideoPlaying(false);
  //   };
  // }, [audioBlob]);

  useEffect(() => {
    // if (unityLoaded) {
    let reply = {
      id: Date.now() + Math.random(),
      type: "reply",
      toUserId: medUser?.userId,
      description: chat?.WELCOME_MESSAGE,
    };
    if (chat?.CHAT?.length < 1) {
      setChatArray([reply]);
    } else {
      setChatArray([reply, ...chat?.CHAT[0]?.messages]);
      // dispatch(getSingleImageCountAction(medUser?.userId, ))
      updateUserPrompt();
    }
    // startInActiveTimer();
    setTotalMessageCount(chat?.TOTAL_MESSAGES);
    // }
    // }, [chat?.WELCOME_MESSAGE, unityLoaded]);
  }, [chat?.WELCOME_MESSAGE, chat?.CHAT]);

  useEffect(() => {
    if (chat?.PROMPT_RESPONSE?.data) {
      let reply = {
        id: Date.now() + Math.random(),
        type: "reply",
        toUserId: medUser?.userId,
        description: chat?.PROMPT_RESPONSE?.data,
      };
      setChatArray([...chatArray, reply]);
      // resetInActiveTimer();
      setFile(null);
    }
    // eslint-disable-next-line
  }, [chat?.PROMPT_RESPONSE?.data]);

  const handleReplyMessage = (item) => {
    if (item.type === "imagePrompt") return;
    if (item.type === "reply" && !spokenMessages.includes(item.id)) {
      // Update gender value according to data
      if (state?.gender !== null && item.description !== null) {
        // speakMessage(item.reply, state?.gender);
        // commented by Mahid
        getAudio(
          item.description,
          state?.gender === "female" ? 1 : 0,
          item.type
        );
        // resetInActiveTimer();
      }
      setSpokenMessages((prevMessages) => [...prevMessages, item.id]);
    }
  };

  useEffect(() => {
    // Speak "reply" messages when the component
    chatArray?.forEach(handleReplyMessage);
  }, [chatArray, audioBlob]);

  let mediaRecorder = null;
  let recordingStart = false;
  let audioStream = null;
  const timerRef = useRef(null);
  const MIN_DECIBELS = -40;

  const stopRecordingVoice = () => {
    mediaRecorder?.stop();
    audioStream?.getTracks()?.forEach((track) => track?.stop());
    recordingStart = false;
    setStartListening(false);
  };

  const startTimeCount = () => {
    timerRef.current = setTimeout(() => {
      // console.log("no voice");
      clearTimeout(timerRef.current);
      stopRecordingVoice();
      // console.log("recording stop");
    }, 5000);
  };

  function handleResetTimer() {
    // console.log("reseting");
    clearTimeout(timerRef.current);
    startTimeCount();
  }

  const startVoiceRecording = async () => {
    // resetInActiveTimer();
    audioStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    mediaRecorder = new MediaRecorder(audioStream);
    mediaRecorder.start();

    recordingStart = true;
    // console.log("recording start");
    startTimeCount();
    const audioChunks = [];
    mediaRecorder.addEventListener("dataavailable", (event) => {
      audioChunks.push(event.data);

      // resetInActiveTimer();
    });

    const audioContext = new AudioContext();
    const audioStreamSource = audioContext.createMediaStreamSource(audioStream);
    const analyser = audioContext.createAnalyser();
    analyser.minDecibels = MIN_DECIBELS;
    audioStreamSource.connect(analyser);

    const bufferLength = analyser.frequencyBinCount;
    const domainData = new Uint8Array(bufferLength);

    let soundDetected = false;

    const detectSound = () => {
      if (!recordingStart) {
        // console.log("not recording");
        return;
      }

      analyser.getByteFrequencyData(domainData);

      for (let i = 0; i < bufferLength; i++) {
        // const value = domainData[i];

        if (domainData[i] > 0) {
          // console.log("sound detected");
          soundDetected = true;
          handleResetTimer();
        }
      }

      window.requestAnimationFrame(detectSound);
    };

    window.requestAnimationFrame(detectSound);

    mediaRecorder.addEventListener("stop", async () => {
      if (soundDetected) {
        const audioBlob = new Blob(audioChunks, {
          type: "audio/wav",
        });

        sendAudioToAPI(audioBlob);
      } else {
        setVoiceLoader(false);
        toast.error("Sound Not Detect");
      }
      // console.log("soundDetected", soundDetected);
      // resetInActiveTimer();
    });
  };

  const checkAndRequestMicrophonePermission = async (startListen = false) => {
    // resetInActiveTimer();
    try {
      // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setIsPermissionGranted(true);

      // audioStream?.getTracks().forEach((track) => track.stop());
      if (startListen) {
        setStartListening(true);
      }
    } catch (error) {
      setIsPermissionGranted(false);
    }
  };

  useEffect(() => {
    // Add the window event listener for beforeunload
    const handleBeforeUnload = () => {
      if (speechSynthesis.speaking) {
        speechSynthesis.cancel();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    // checkAndRequestMicrophonePermission();
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);

      // Stop speech synthesis when the component unmounts
      if (speechSynthesis.speaking) {
        speechSynthesis.cancel();
      }
    };
  }, []);

  // function isPlaying(audelem) {
  //   return !audelem.paused;
  // }

  const sendAudioToAPI = async (audioData) => {
    setVoiceLoader(true);
    try {
      const formData = new FormData();
      formData.append("audio_file", audioData, "recorded-audio.wav");
      // formData.append("language_code", state?.language);
      const response = await axios.post(
        `${BASE_URL}/speech_to_text?language_code=${state?.language}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      await setSpeechToTextResponse(response?.data?.response);
      if (response?.data?.response !== "") {
        setVoiceLoader(false);
        sendPrompt(response.data.response);
      } else {
        setVoiceLoader(false);
        toast.error("Sound not detect");
      }

      // setSentVoice(false);
      // setAudioBlob(null);
    } catch (error) {
      console.error("Error sending audio to API:", error);
    }
  };

  // useEffect(() => {
  //   if (audioBlob && sentVoice) {
  //     sendAudioToAPI();
  //   }
  // }, [audioBlob, sentVoice]);

  const startVoiceCommand = async () => {
    // resetInActiveTimer();
    await setVideoPlaying(false);
    setAudioBlob(null);
    if (!videoPlaying) {
      if (isPermissionGranted) {
        setStartListening(true);
      } else {
        // Request microphone permission
        checkAndRequestMicrophonePermission(true);
      }
    }
  };

  useEffect(() => {
    if (startListening) {
      var resetTimer = setTimeout(() => {
        // resetInActiveTimer();
      }, 1000);
      startVoiceRecording();
    }
    return () => {
      clearTimeout(resetTimer);
    };
  }, [startListening]);

  const { width } = useWindowSizeHook();

  const audioRef = useRef(null);

  const handlePlayPause = () => {
    if (audioRef?.current?.paused) {
      // audioRef.current.play();
    } else {
      // audioRef.current.pause();
      // resetInActiveTimer();
    }
  };

  useEffect(() => {
    let handlePlayPauseTimer = setTimeout(() => {
      handlePlayPause();
    }, 10000);
    return () => {
      clearTimeout(handlePlayPauseTimer);
    };
  }, []);

  const handlePause = () => {
    // Check if the player reference exists
    if (playerRef?.current) {
      // Set the current time of the video to 0 seconds
      playerRef?.current?.seekTo(0);
    }
  };

  const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      setPreviewImg(imageUrl);
    }
  };

  const sessionEndSuccess = () => {
    let finalData = {
      chat_id: chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId || chatId,
      patientId: medUser?.userId,
    };
    dispatch(generateChatNameAction(finalData));
    // navigate(ROUTER_PATH.THANK_YOU);
    navigate(ROUTER_PATH.THANK_YOU, {
      state: {
        chatId: chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId || chatId,
      },
    });
  };

  const handleEndSession = () => {
    if (chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId || chatId) {
      dispatch(
        endSessionAction(
          {
            chatId:
              chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId || chatId,
          },
          sessionEndSuccess
        )
      );
    } else {
      navigate(ROUTER_PATH.HOME_PAGE);
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.muted = isMuted;
    }
  }, [isMuted, audioBlob]);

  const toggleMute = () => {
    setIsMuted((prevState) => !prevState);
  };

  // const handleMedicalRecord = (e) => {
  //   const file = e.target.files[0];
  //   if (file && file.type.startsWith("image/")) {
  //     setUploadedImage(URL.createObjectURL(file));
  //   }
  // };

  // const uploadMediaAttachment = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const formData = new FormData();
  //     formData.append("Image", file, file.name);

  //     uploadMedia(formData).then(
  //       (response) => {
  //         if (response?.data?.succeeded === true) {
  //           setUploadMedicalRecord({
  //             imageUrl: response.data.data.imageUrl,
  //             imageKey: response.data.data.imageKey,
  //             answer: response.data.data.answer,
  //           });
  //         }
  //       },
  //       (error) => {
  //         console.error("Error uploading media", error);
  //       }
  //     );
  //   }
  // };

  // const uploadMediaAttachment = async (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const formData = new FormData();
  //     formData.append("Image", file, file.name);

  //     setImageUploadLoading(true);

  //     uploadMedia(formData, state?.language)
  //       .then(
  //         (response) => {
  //           setImageUploadLoading(false);
  //           setImageCount(imageCount + 1);
  //           if (
  //             !response ||
  //             !response.data ||
  //             response.data.succeeded !== true
  //           ) {
  //             console.error("Failed upload or null response:", response);
  //             toast.error(
  //               `Error uploading media due to ${response.data} response. Please try again.`
  //             );
  //             return;
  //           }

  //           const { imageUrl, answer, imageKey } = response.data.data || {};

  //           if (!imageUrl || !answer || !imageKey) {
  //             console.error("Incomplete response data:", response.data);
  //             toast.error("Incomplete data. Please try again.");
  //             return;
  //           }

  //           const imageMessage = {
  //             id: Date.now() + Math.random(),
  //             type: "imagePrompt",
  //             imageUrl: imageUrl,
  //           };

  //           dispatch(
  //             saveMessage({
  //               fromUserId: medUser?.userId,
  //               toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
  //               chatId: chat?.CHAT?.[0]?.chatId || chatId,
  //               isFile: true,
  //               description: imageKey,
  //               isFirstMessage: false,
  //             })
  //           );

  //           const replyMessage = {
  //             id: Date.now() + Math.random(),
  //             type: "reply",
  //             toUserId: medUser?.userId,
  //             description: answer,
  //           };

  //           dispatch(
  //             saveMessage({
  //               toUserId: medUser?.userId,
  //               fromUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
  //               chatId: chat?.CHAT?.[0]?.chatId || chatId,
  //               isFile: false,
  //               description: answer,
  //               isFirstMessage: false,
  //             })
  //           );

  //           setChatArray((prevChatArray) => [
  //             ...prevChatArray,
  //             imageMessage,
  //             replyMessage,
  //           ]);
  //         },
  //         (error) => {
  //           setImageUploadLoading(false);
  //           console.error("Error uploading media", error);
  //           toast.error("Error uploading media. Please try again.");
  //         }
  //       )
  //       .catch((error) => {
  //         setImageUploadLoading(false);
  //         console.error("Unhandled error:", error);
  //         toast.error("Unhandled error. Please try again.");
  //       });
  //   }
  // };

  const uploadMediaAttachment = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("Image", file, file.name);

      try {
        setImageUploadLoading(true);
        // Upload media
        const response = await uploadMedia(formData, state?.language);
        setImageUploadLoading(false);
        setImageCount(imageCount + 1);

        if (!response || !response.data || response.data.succeeded !== true) {
          console.error("Failed upload or null response:", response);
          toast.error(`Error uploading media. Please try again.`);
          return;
        }

        const { imageUrl, answer, imageKey } = response.data.data || {};
        if (!imageUrl || !answer || !imageKey) {
          console.error("Incomplete response data:", response.data);
          toast.error("Incomplete data. Please try again.");
          return;
        }

        const imageMessage = {
          id: Date.now() + Math.random(),
          type: "imagePrompt",
          imageUrl: imageUrl,
        };

        // Attempt to get existing chatId or create new chat

        let currentChatId = chat?.CHAT?.[0]?.chatId || chatId;
        if (!currentChatId) {
          console.log("Creating a new chat as no existing chatId found.");

          // Save chat and wait for chatId to be set in state
          // await new Promise((resolve) => {
          dispatch(
            saveChatAction(
              {
                name: "",
                fromUserId: medUser?.userId,
                toUserId: chat?.DOCTOR_PROFILE?.data?.[0]?.doctorId,
                roleId: state?.gender === "male" ? 101 : 102,
                language: state?.language,
              },
              (resp) => {
                const newChatId = resp?.data?.succeeded && resp?.data;
                if (newChatId) {
                  currentChatId = resp?.data?.data;
                  setChatId(currentChatId);
                  const replyMessage = {
                    id: Date.now() + Math.random(),
                    type: "reply",
                    toUserId: medUser?.userId,
                    description: answer,
                  };

                  function moveToNextOnUploadImageDBSave() {
                    dispatch(
                      saveMessage({
                        toUserId: medUser?.userId,
                        fromUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
                        chatId: currentChatId,
                        isFile: false,
                        description: answer,
                        isFirstMessage: false,
                        planId: medUser?.currentPlanId,
                      })
                    );
                  }
                  // Dispatch saveMessage with the chatId
                  dispatch(
                    // await dispatch(
                    saveMessage(
                      {
                        fromUserId: medUser?.userId,
                        toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
                        chatId: currentChatId,
                        isFile: true,
                        description: imageKey,
                        isFirstMessage: false,
                        planId: medUser?.currentPlanId,
                      },
                      moveToNextOnUploadImageDBSave
                    )
                  );

                  setChatArray((prevChatArray) => [
                    ...prevChatArray,
                    imageMessage,
                    replyMessage,
                  ]);
                  // resolve();
                } else {
                  toast.error(
                    "Chat created successfully, but chatId is missing. Please try again."
                  );
                  // resolve(); // Resolve the promise to continue execution
                }
              }
            )
          );
          // });
        }
        if (!currentChatId) return; // Exit if no chatId was set

        if (currentChatId || chatId) {
          const replyMessage = {
            id: Date.now() + Math.random(),
            type: "reply",
            toUserId: medUser?.userId,
            description: answer,
          };

          function moveToNextOnUploadImageDBSave() {
            dispatch(
              saveMessage({
                toUserId: medUser?.userId,
                fromUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
                chatId: currentChatId,
                isFile: false,
                description: answer,
                isFirstMessage: false,
                planId: medUser?.currentPlanId,
              })
            );
          }
          // Dispatch saveMessage with the chatId
          dispatch(
            // await dispatch(
            saveMessage(
              {
                fromUserId: medUser?.userId,
                toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
                chatId: currentChatId,
                isFile: true,
                description: imageKey,
                isFirstMessage: false,
                planId: medUser?.currentPlanId,
              },
              moveToNextOnUploadImageDBSave
            )
          );

          setChatArray((prevChatArray) => [
            ...prevChatArray,
            imageMessage,
            replyMessage,
          ]);
        }
      } catch (error) {
        setImageUploadLoading(false);
        console.error("Error uploading media", error);
        toast.error("Error uploading media. Please try again.");
      }
    }
  };

  function getImageCount(response) {
    if (response?.data?.[0]?.chatId) {
      dispatch(
        getSingleImageCountAction(medUser?.userId, response?.data?.[0]?.chatId)
      );
    }
  }

  useEffect(() => {
    if (chat?.CHAT[0]?.chatId) {
      dispatch(
        getSingleImageCountAction(medUser?.userId, chat?.CHAT[0]?.chatId)
      );
    } else {
      dispatch(getSingleImageCountAction(medUser?.userId));
    }
  }, []);

  useEffect(() => {
    if (chat?.GET_IMAGE_COUNT?.data) {
      setImageCount(chat?.GET_IMAGE_COUNT?.data);
    }
    // if (chat?.GET_IMAGE_COUNT) setImageCount(chat?.GET_IMAGE_COUNT?.data);
  }, [chat?.GET_IMAGE_COUNT]);

  console.log({ imageCount });
  const getMaxImageUploadLimit = (planId) => {
    switch (planId) {
      case 1:
        return 1;
      case 2:
      case 4:
        return 3;
      case 3:
      case 5:
        return 15;
      default:
        return 0;
    }
  };
  const getPlanLimits = (planId) => {
    switch (planId) {
      case 1:
        return { maxMessages: 10, maxImages: 1 };
      case 2:
      case 4:
        return { maxMessages: 100, maxImages: 3 };
      default:
        return null;
    }
  };

  const isLimitReached = () => {
    const limits = getPlanLimits(medUser?.planId);
    if (!limits) return false;

    const messagesExceedLimit = totalMessageCount >= limits.maxMessages;
    const imagesExceedLimit = imageCount >= limits.maxImages;

    return messagesExceedLimit || imagesExceedLimit;
  };

  return (
    <div className="d-lg-flex" id="app-content">
      <Box
        width={
          width < 992
            ? "25%"
            : width < 1300
              ? "20rem"
              : width < 1500
                ? "25rem"
                : "30rem"
        }
        height="100%"
        borderRadius="1.375rem"
        background={width < 992 ? "" : "#fff"}
        shadow={width < 992 ? "" : "0px 0px 40px 20px rgba(0, 0, 0, 0.09)"}
        backdropFilter="blur(15px)"
        className={` ${width < 992
          ? "float-end  mt-3"
          : "mb-5 mb-lg-0 chat-history-box me-3 py-3 chat-box-a"
          } `}
      >
        <div>
          <Typography
            className={`${width < 992 ? "d-none" : ""}`}
            as="h6"
            align="center"
            color="rgb(147, 112, 219)"
          >
            {state?.gender === "male" ? "Dr. William" : "Dr. Elizabeth"}
          </Typography>
        </div>
        <div
          className={` ${width < 992
            ? "m-0"
            : "video-container text-center w-100 mb-auto mt-2 position-relative"
            }  `}
        >
          <div className="d-none">
            {audioBlob && (
              <audio
                autoPlay
                ref={audioRef}
                onEnded={() => {
                  setVideoPlaying(false);
                  setAudioBlob(null);
                  // resetInActiveTimer();
                }}
                src={audioBlob}
              >
                Your browser does not support the audio tag.
              </audio>
            )}
          </div>

          <ReactPlayer
            url={
              state?.gender === "male"
                ? IMAGES.MALE_DOCTOR
                : IMAGES.FEMALE_DOCTOR
            }
            ref={videoPlaying ? playerRef : null}
            muted
            playing={videoPlaying}
            loop={videoPlaying}
            width={`${width < 992 ? "90%" : "100%"}`}
            height={"100%"}
            onPause={handlePause}
          />
          {/* {state?.gender === "male" ? (
            <ReactPlayer
              url={IMAGES.MALE_DOCTOR}
              ref={playerRef}
              muted
              playing={videoPlaying}
              loop={videoPlaying}
              width={`${width < 992 ? "90%" : "100%"}`}
              height={"100%"}
              onPause={handlePause}
            />
          ) : (
            state?.gender === "female" && (
              <ReactPlayer
                url={IMAGES.FEMALE_DOCTOR}
                ref={playerRef}
                muted
                playing={videoPlaying}
                loop={videoPlaying}
                width={`${width < 992 ? "90%" : "100%"}`}
                height={"100%"}
                onPause={handlePause}
              />
            )
          )} */}
        </div>
        <div className={`${width < 992 ? "d-none" : "w-100"}`}>
          <Row className="justify-content-start m-0 px-2 mb-3">
            <Col sm={12}>
              <Row>
                {state?.vitals?.map((item) => (
                  <Col sm={6}>
                    <Typography as="label" color="#636967" className="mt-2">
                      {item.title}
                    </Typography>
                    <Typography as="p" color="#4A4F4D">
                      {item.desc}
                    </Typography>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
        <div
          className={`${width < 992
            ? "d-none"
            : "px-3 my-2 w-100 d-flex align-items-center justify-content-center flex-column gap-2"
            }`}
        >
          <input
            ref={inputRef}
            onChange={handleImageUpload}
            className="d-none"
            type="file"
          />
        </div>
        <div className="px-3 w-100 d-flex align-items-center justify-content-center  ">
          <Button
            height="3rem"
            minWidth={width < 1300 ? "8rem" : "10rem"}
            // disabled={medUser?.currentPlanId === 1}
            className={`${width < 992 ? "d-none" : ""} mx-auto`}
            onClick={() => {
              handleEndSession();
            }}
            disabled={chat.SAVE_CHAT_LOADING}
          >
            <Typography
              color="#fff"
              fontSize="1.5rem"
              fontWeight="500"
              className="text-center w-100"
            >
              {chat?.END_SESSION_LOADING ? <GenericLoader /> : "End Session"}
            </Typography>
          </Button>
          {/* <Button
            height="3rem"
            minWidth={width < 1300 ? "6rem" : "10rem"}
            // disabled={medUser?.currentPlanId === 1}
            className={`${width < 992 ? "d-none" : ""}`}
            backgroundColor="rgb(83 147 44)"
            onClick={() => {
              setPlayPause(!playPause);
              setAudioBlob(null);
              setVideoPlaying(false);
              handlePause();
            }}
          >
            <Typography
              color="#fff"
              fontSize="1.5rem"
              fontWeight="500"
              className="text-center w-100"
            >
              {playPause ? "Unmute" : "Mute"}
            </Typography>
          </Button> */}
          <div
            className={`${width < 992 ? "d-none" : ""}`}
            onClick={() => {
              toggleMute();
              // setPlayPause(!playPause);
              // setAudioBlob(null);
              // setVideoPlaying(false);
              // handlePause();
            }}
          >
            {isMuted ? (
              <VolumeOffIcon
                className="fs-1 "
                style={{ color: "rgb(83 147 44)" }}
              />
            ) : (
              <VolumeUpIcon
                className="fs-1 "
                style={{ color: "rgb(83 147 44)" }}
              />
            )}
          </div>
        </div>
      </Box>
      <Box
        width="100%"
        height="100%"
        maxHeight="35rem"
        maxWidth="202rem"
        borderRadius="1.375rem"
        background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
        shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
        backdropFilter="blur(15px)"
        className="pt-5 mb-auto mb-5 w-100 chat-box-b"
      >
        <div>
          <Typography
            as={width < 600 ? "h6" : "h5"}
            align="center"
            color="rgb(147, 112, 219)"
          >
            Patient Diagnosis
          </Typography>
          <div
            className={
              width < 992 && width > 800
                ? "d-flex justify-content-end align-items-end mt-3 "
                : width < 992
                  ? "d-flex justify-content-end align-items-end "
                  : "d-none"
            }
          >
            <div className=""></div>
            {width < 650 ? (
              <div className="d-flex justify-content-end align-items-center gap-2">
                <FaPowerOff
                  onClick={() => handleEndSession()}
                  size={25}
                  color="#ff0000ba"
                />
                <div onClick={() => toggleMute()}>
                  {isMuted ? (
                    <VolumeOffIcon
                      className="fs-1 me-3"
                      style={{ color: "rgb(83 147 44)" }}
                    />
                  ) : (
                    <VolumeUpIcon
                      className="fs-1 me-3"
                      style={{ color: "rgb(83 147 44)" }}
                    />
                  )}
                </div>
              </div>
            ) : (
              <>
                <Button
                  onClick={() => handleEndSession()}
                  className={` text-white fw-normal me-3 ${width > 650 && width < 700
                    ? "mt-2"
                    : width > 700 && width < 750
                      ? "mt-3"
                      : width > 750 && width < 770
                        ? "mt-4"
                        : ""
                    }`}
                  borderRadius="8px"
                  height="2.7rem"
                  minWidth="7rem"
                >
                  End Session
                </Button>
                <div
                  className={` text-white fw-normal me-3 ${width > 650 && width < 700
                    ? "mt-2"
                    : width > 700 && width < 750
                      ? "mt-3"
                      : width > 750 && width < 770
                        ? "mt-4"
                        : ""
                    }`}
                  onClick={() => {
                    toggleMute();
                    // setPlayPause(!playPause);
                    // setAudioBlob(null);
                    // setVideoPlaying(false);
                    // handlePause();
                  }}
                >
                  {isMuted ? (
                    <VolumeOffIcon
                      className="fs-1 "
                      style={{ color: "rgb(83 147 44)" }}
                    />
                  ) : (
                    <VolumeUpIcon
                      className="fs-1 "
                      style={{ color: "rgb(83 147 44)" }}
                    />
                  )}
                </div>
                {/* <Button
                  onClick={() => {
                    setPlayPause(!playPause);
                    setAudioBlob(null);
                    setVideoPlaying(false);
                  }}
                  className={` text-white fw-normal me-3 ${
                    width > 650 && width < 700
                      ? "mt-2"
                      : width > 700 && width < 750
                      ? "mt-3"
                      : width > 750 && width < 770
                      ? "mt-4"
                      : ""
                  }`}
                  borderRadius="8px"
                  height="2.7rem"
                  minWidth="6rem"
                  backgroundColor="rgb(83 147 44)"
                >
                  {playPause ? "Play" : "Pause"}
                </Button> */}
              </>
            )}
          </div>
        </div>
        <Box
          width="100%"
          height="calc(100vh - 28.5rem)"
          minHeight="450px"
          background="transparent"
          className="px-sm-1 px-lg-5 mt-4"
          overflowY="auto"
          id="chatBox"
        >
          {chatArray?.map((item, index) => {
            const isReply = item?.toUserId === medUser?.userId;

            return isReply ? (
              <ReplyTemplate
                reply={item?.reply ? item?.reply : item?.description}
                // loader={
                //   chat?.GET_WELCOME_MESSAGE_IS_LOADING || imageUploadingLoading
                // }
                getAudio={getAudio}
                // resetInActiveTimer={resetInActiveTimer}
                gender={state?.gender}
                videoPlaying={videoPlaying}
                setVideoPlaying={setVideoPlaying}
                setAudioBlob={setAudioBlob}
                setIsMuted={setIsMuted}
              // image={item?.imageUrl}
              />
            ) : (
              <MessageTemplate
                fromUser={item?.fromUser}
                message={item?.message || item?.description}
                image={item?.fileUrl || item.imageUrl}
              />
            );
          })}

          {(chat?.GET_WELCOME_MESSAGE_IS_LOADING ||
            chat?.GET_PROMPT_RESPONSE_IS_LOADING ||
            voiceLoader ||
            imageUploadingLoading) && <Loader size={10} className="m-3" />}
        </Box>

        <Box
          width="100%"
          background="var(--secondary-150, #F4FBEA)"
          borderRadius="0.75rem 0.75rem 0 0"
          className="d-flex flex-wrap justify-content-between py-2 px-sm-1 px-lg-3"
        >
          {/* Voice Recording Section */}
          <div className="d-flex align-items-center justify-content-center bg-red-700">
            {startListening && chat?.GET_PROMPT_RESPONSE_IS_LOADING !== true ? (
              <Box
                width="2.2rem"
                height="2.2rem"
                background="#ED6A58"
                className="rounded-circle d-flex justify-content-center align-items-center mx-2 cursor-pointer"
                onClick={() => {
                  setStartListening(false);
                  stopRecordingVoice();
                }}
              >
                <BsFillStopFill color="white" size={width > 1360 ? 24 : 15} />
              </Box>
            ) : (
              <Box
                width="2.2rem"
                height="2.2rem"
                background={
                  chat?.GET_PROMPT_RESPONSE_IS_LOADING ? "#898989" : "#53972C"
                }
                className={`rounded-circle d-flex justify-content-center align-items-center mx-2 mt-1 ${chat?.GET_PROMPT_RESPONSE_IS_LOADING
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
                  }`}
                onClick={
                  chat?.GET_PROMPT_RESPONSE_IS_LOADING
                    ? null
                    : isLimitReached()
                      ? () => setIsVisible(true)
                      : startVoiceCommand
                }
                // onClick={
                //   chat?.GET_PROMPT_RESPONSE_IS_LOADING
                //     ? null
                //     : medUser?.planId === 1 &&
                //       (userPrompt?.length >= 10 ||
                //         (userPrompt?.length >= 9 && imageCount >= 1) ||
                //         totalMessageCount >= 10)
                //     ? () => setIsVisible(true)
                //     : startVoiceCommand
                // }
                style={{
                  pointerEvents: chat?.GET_PROMPT_RESPONSE_IS_LOADING
                    ? "none"
                    : "auto",
                  opacity: chat?.GET_PROMPT_RESPONSE_IS_LOADING ? 0.6 : 1,
                }}
              >
                <BsFillMicMuteFill
                  color="#ffffff"
                  size={width > 1360 ? 20 : 15}
                />
              </Box>
            )}

            {startListening &&
              chat?.GET_PROMPT_RESPONSE_IS_LOADING !== true && (
                <Loader size={10} />
              )}

            {!startListening && (
              <b className="me-2">
                <small style={{ fontSize: width < 600 ? "11px" : "13px" }}>
                  If you prefer to speak to the doctor, rather than type, please
                  press the mic button to speak.
                </small>
              </b>
            )}
          </div>
        </Box>
        {/* Image Upload Processing */}
        {console.log("medUser?.currentPlanId - 1", medUser)}
        <div className="input-field chat_icon_flex padding-upload-icon">
          {/* <label>
            <div
              className="circle-btn"
              style={{ marginRight: "10px" }}
              onClick={() => {
                if (imageCount >= [1, 3, 15][medUser?.currentPlanId - 1]) {
                  // Show toast notification for image upload limit
                  toast.info(
                    // `You can upload only ${[1, 3, 15][medUser?.currentPlanId - 1]
                    // } attachment${medUser?.currentPlanId === 1
                    //   ? " in Free Subscription"
                    //   : medUser?.currentPlanId === 2
                    //     ? " in Premium Subscription"
                    //     : " in Professional Subscription"
                    // }.`
                    "You've hit the maximum number of uploads allowed for your subscription. To continue uploading, please upgrade your plan"
                  );
                }
              }}
            >
              <span
                className={`plus-sign ${imageCount >= [1, 3, 15][medUser?.currentPlanId - 1] ||
                  chat?.GET_PROMPT_RESPONSE_IS_LOADING ||
                  imageUploadingLoading
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
                  }`}
              >
                +
              </span>
            </div>

            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              // accept="image/*"
              style={{ display: "none" }}
              onClick={(e) => {
                if (
                  imageUploadingLoading ||
                  imageCount >= [1, 3, 15][medUser?.currentPlanId - 1]
                ) {
                  e.preventDefault(); // Prevents the file dialog from opening
                }
              }}
              onChange={(e) => {
                if (userPrompt?.length >= 10 && medUser?.currentPlanId === 1) {
                  setIsVisible(true);
                } else {
                  uploadMediaAttachment(e);
                  setImageUploadLoading(true);
                }
              }}
              disabled={
                imageCount >= [1, 3, 15][medUser?.currentPlanId - 1] ||
                chat?.GET_PROMPT_RESPONSE_IS_LOADING ||
                imageUploadingLoading ||
                (medUser?.planId === 1 &&
                  (userPrompt?.length >= 10 || (userPrompt?.length >= 9 && imageCount >= 1) || totalMessageCount >= 10))
              }
            />
          </label> */}
          <label>
            <div
              className="circle-btn"
              style={{ marginRight: "10px" }}
              onClick={() => {
                if (
                  imageCount >= getMaxImageUploadLimit(medUser?.currentPlanId)
                ) {
                  // Show toast notification for image upload limit
                  toast.info(
                    `You've hit the maximum number of uploads allowed for your subscription. To continue uploading, please upgrade your plan.`
                  );
                }
              }}
            >
              <span
                className={`plus-sign ${imageCount >=
                  getMaxImageUploadLimit(medUser?.currentPlanId) ||
                  chat?.GET_PROMPT_RESPONSE_IS_LOADING ||
                  imageUploadingLoading
                  ? "cursor-not-allowed"
                  : "cursor-pointer"
                  }`}
              >
                +
              </span>
            </div>

            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              style={{ display: "none" }}
              onClick={(e) => {
                if (
                  imageUploadingLoading ||
                  imageCount >= getMaxImageUploadLimit(medUser?.currentPlanId)
                ) {
                  e.preventDefault(); // Prevents the file dialog from opening
                }
              }}
              onChange={(e) => {
                if (userPrompt?.length >= 10 && medUser?.currentPlanId === 1) {
                  setIsVisible(true);
                } else {
                  uploadMediaAttachment(e);
                  setImageUploadLoading(true);
                }
              }}
              disabled={
                imageCount >= getMaxImageUploadLimit(medUser?.currentPlanId) ||
                chat?.GET_PROMPT_RESPONSE_IS_LOADING ||
                imageUploadingLoading ||
                (medUser?.planId === 1 &&
                  (userPrompt?.length >= 10 ||
                    (userPrompt?.length >= 9 && imageCount >= 1) ||
                    totalMessageCount >= 10))
              }
            />
          </label>

          {/*Input Field  */}

          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <GenericInput
              placeholder="Enter your message"
              fontSize={width < 600 ? "14px" : "1rem"}
              width="100%"
              height="60px"
              minHeight="100%"
              value={textareaValue}
              onChange={handleTextareaChange}
              onKeyPress={handleKeyPress}
              as="textarea"
              border="none"
              borderRadius="0 0 1.375rem 2rem"
              maxLength={1000}
              disabled={!!uploadedImage}
            />

            <button
              onClick={handleButtonClick}
              disabled={
                !textareaValue.trim() ||
                imageUploadingLoading ||
                chat?.GET_PROMPT_RESPONSE_IS_LOADING
              }
              style={{
                background: "none",
                border: "none",
                cursor:
                  (!textareaValue.trim() && !uploadedImage) ||
                    chat?.GET_PROMPT_RESPONSE_IS_LOADING
                    ? "not-allowed"
                    : "pointer",
                padding: "0 10px",
                opacity:
                  (!textareaValue.trim() && !uploadedImage) ||
                    chat?.GET_PROMPT_RESPONSE_IS_LOADING
                    ? 0.5
                    : 1,
              }}
            >
              <FaCircleArrowUp size={30} color="rgb(147, 112, 219)" />
              {/* <FaCircleArrowUp size={30} color="rgb(90 10 252)" /> */}
            </button>
          </div>
        </div>
      </Box>
      {/*  */}
      {confirmationModalShow && (
        <ConfirmationModal
          show={confirmationModalShow}
          onHide={() => setConfirmationModalShow(false)}
          size="md"
          title={<Typography as="h6">Save Chat in History</Typography>}
          successAction={handleSaveChat}
          loader={chat?.SAVE_CHAT_HISTORY_IS_LOADING}
        >
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <Typography as="p" size="1.5rem">
                Chat Name
              </Typography>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name of Chat"
              onChange={(e) => {
                setChatName(e.target.value);
              }}
              autoFocus
            />
          </Form.Group>
        </ConfirmationModal>
      )}

      <ConfirmationModal
        show={inActiveModal}
        onHide={() => setInActiveModal(false)}
        size="md"
        hideFooter={true}
        className="d-flex justify-content-center align-items-center custom-modal-style"
      >
        <div className="text-center w-100 px-2 px-md-5">
          <Typography
            align="center"
            fontSize={width < 500 ? "1rem" : "1.2rem"}
            fontWeight="500"
            className="p-3"
            m
          >
            Hey, are you still with us?
          </Typography>
          <div className="d-flex gap-3  justify-content-center align-items-center">
            <Button
              className="bg-secondary"
              minWidth={width < 500 ? "6rem" : "8rem"}
              height={width < 500 ? "2.5rem" : "3rem"}
              onClick={() => {
                handleEndSession();
              }}
            >
              <Typography
                align="center"
                fontSize="20px"
                fontWeight="500"
                color="#fff"
              >
                No
              </Typography>
            </Button>
            <Button
              minWidth={width < 500 ? "6rem" : "8rem"}
              height={width < 500 ? "2.5rem" : "3rem"}
              onClick={() => {
                setInActiveModal(false);
                // resetInActiveTimer();
              }}
              className="px-4 py-2 text-white"
              variant="success"
            >
              <Typography
                color="#fff"
                fontSize="20px"
                fontWeight="400"
                className="text-center w-100"
              >
                Yes
              </Typography>
            </Button>
          </div>
        </div>
      </ConfirmationModal>

      <GenericModal
        show={previewVisible}
        onHide={() => setPreviewVisible(false)}
      >
        <img alt="Preview" style={{ width: "100%" }} src={previewImg} />
      </GenericModal>
      <UpgradeModal isVisible={isVisible} setIsVisible={setIsVisible} />
    </div>
  );
}
