import {
  UpdateSubscriptionApi,
  cancelUserSubscriptions,
  checkSubscriptionApi,
  createSubscriptionApi,
  getAllPlan,
} from "../api/subscription";

export function createSubscriptionAction(payload, moveToNext) {
  return (dispatch) => {
    dispatch({
      type: "CREATE_SUBSCRIPTION_REQUEST",
    });
    createSubscriptionApi(payload).then(
      (response) => {
        if (response?.status === 200 || response?.succeeded) {
          dispatch({
            type: "CREATE_SUBSCRIPTION_SUCCESS",
            payload: response.data,
          });
          if (moveToNext) moveToNext(response);
        }
      },
      (error) => {
        dispatch({
          type: "CREATE_SUBSCRIPTION_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}
export function updateSubscriptionAction(payload, moveToNext) {
  return (dispatch) => {
    dispatch({
      type: "CREATE_SUBSCRIPTION_REQUEST",
    });
    UpdateSubscriptionApi(payload).then(
      (response) => {
        if (response?.status === 200 || response?.succeeded) {
          dispatch({
            type: "CREATE_SUBSCRIPTION_SUCCESS",
            payload: response.data,
          });
          if (moveToNext) moveToNext(response);
        }
      },
      (error) => {
        dispatch({
          type: "CREATE_SUBSCRIPTION_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

export function checkSubscriptionAction(payload, moveToNext) {
  return (dispatch) => {
    dispatch({
      type: "CHECK_SUBSCRIPTION_REQUEST",
    });
    checkSubscriptionApi(payload).then(
      (response) => {
        if (response?.status === 200 || response?.succeeded) {
          dispatch({
            type: "CHECK_SUBSCRIPTION_SUCCESS",
            payload: response.data,
          });
          if (moveToNext) moveToNext(response?.data);
        }
      },
      (error) => {
        dispatch({
          type: "CHECK_SUBSCRIPTION_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

export function getAllUserPlans(roleId) {
  return (dispatch) => {
    dispatch({
      type: "GET_ALL_PLANS_REQUEST",
    });
    getAllPlan(roleId).then(
      (response) => {
        if (response?.status === 200 || response?.succeeded) {
          dispatch({
            type: "GET_ALL_PLANS_SUCCESS",
            payload: response.data.data,
          });
        }
      },
      (error) => {
        dispatch({
          type: "GET_ALL_PLANS_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

export function cancelSubscriptions(id, moveToNext) {
  return (dispatch) => {
    dispatch({
      type: "CANCEL_SUBSCRIPTION_REQUEST",
    });
    cancelUserSubscriptions(id).then(
      (response) => {
        if (response?.status === 200 || response?.succeeded) {
          dispatch({
            type: "CANCEL_SUBSCRIPTION_SUCCESS",
            payload: response.data.data,
          });
        }
        if(moveToNext) moveToNext(response)
      },
      (error) => {
        dispatch({
          type: "CANCEL_SUBSCRIPTION_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}
