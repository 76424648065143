import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

import GenericLoader from "../../Loader";
import { useEffect } from "react";

export default function ConfirmationModal({ children, ...props }) {
  useEffect(() => {
    const content = document.getElementById("app-content");

    if (content) {
      if (props.show) {
        content.classList.add("modal-blur-background");
      } else {
        content.classList.remove("modal-blur-background");
      }
    }

    return () => {
      if (content) {
        content.classList.remove("modal-blur-background");
      }
    };
  }, [props.show]);
  return (
    <Modal
      size={props.size}
      show={props.show}
      onHide={props.onHide}
      centered={props.centered}
      className={props.className}
      aria-labelledby="modal"
    >
      {props.title && <Modal.Header>{props.title}</Modal.Header>}
      <Modal.Body className="p-4">{children}</Modal.Body>
      {!props?.hideFooter && (
        <Modal.Footer className="d-flex justify-content-between align-items-center">
          <div>
            {props.loader && <GenericLoader animation="border" size="10px" />}
          </div>
          <div>
            <Button
              variant="secondary"
              onClick={props.onHide}
              className="px-4 py-2 mx-2"
            >
              No
            </Button>
            <Button
              onClick={props.successAction}
              className="px-4 py-2"
              variant="success"
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  centered: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  successAction: PropTypes.func,
  loader: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  centered: true,
  size: "md",
  className: "",
  title: "",
  children: null,
  successAction: () => {},
  loader: false,
  hideFooter: false,
};
