import React from "react";

export const VerticalProgressBar = ({ progress, horizontal }) => {
  const style = {
    height: horizontal ? "8px" : "400px", // Adjust height as needed
    width: horizontal ? "100%" : "8px", // Adjust width as needed
    backgroundColor: "#23167115",
    position: "relative",
    borderRadius: "15px",
  };

  const barStyle = {
    borderRadius: "15px",
    position: "absolute",
    bottom: 0,
    left: 0,
    backgroundColor: "#9370DB",
    width: horizontal ? `${(progress + 1) * (100 / 16)}%` : "100%",
    height: horizontal ? "100%" : `${(progress + 1) * (100 / 16)}%`,
    transition: "height 0.5s ease-in-out",
  };

  return (
    <div style={style}>
      <div style={barStyle}></div>
    </div>
  );
};
