import React from "react";
import { Col, Row } from "react-bootstrap";
import { Box, Button, GenericLoader, Typography } from "../../Components";
import { useSelector } from "react-redux";
import useWindowSizeHook from "../../Components/WindowSizeHook";
import { ROUTER_PATH } from "../../config";
import { useNavigate } from "react-router-dom";

function VerifyEmail({ setIsWelcome }) {
  const UserData = useSelector((state) => state?.users);
  const { width: useWidth } = useWindowSizeHook();
  console.log("loading", UserData);
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      <Row
        className="vh-100 flex justify-content-center align-items-center p-3 no-gutters"
        style={{ "--bs-gutter-x": "0" }}
      >
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-center align-items-center "
        >
          <Box
            borderRadius="1.375rem"
            background="#fff"
            shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.03)"
            backdropFilter="blur(5px)"
            className="d-flex flex-column p-5   align-items-center justify-content-between"
          >
            <Typography
              fontWeight="600"
              fontSize={useWidth < 500 ? "1.3rem" : "2rem"}
              className="mb-4 text-center"
              color="#9370DB"
            >
              Welcome to MediStreamline
            </Typography>
            {UserData?.VERIFY_ACCOUNT_IS_LOADING ? (
              <GenericLoader />
            ) : UserData?.VERIFY_ACCOUNT_IS_SUCCESS ? (
              <>
                <div
                  style={{
                    borderRadius: "100px",
                    height: "100px",
                    width: "100px",
                    background: "#F8FAF5",
                    margin: "10px auto",
                  }}
                >
                  <i class="checkmark">✓</i>
                </div>
                <Typography fontWeight="500" fontSize="1.5rem" color="#9ABC66">
                  Verification Success
                </Typography>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <p className="mb-0 fs-5 text-danger">Something went Wrong </p>
                  <p className="mb-0 fs-5 text-danger">Please Try Again</p>
                </div>
              </>
            )}
            <Button
              disabled={UserData?.VERIFY_ACCOUNT_IS_LOADING ? true : false}
              type="button"
              className={
                UserData?.VERIFY_ACCOUNT_IS_LOADING
                  ? "mt-5 cursor-not-allowed"
                  : "mt-5"
              }
              onClick={() => {
                UserData?.VERIFY_ACCOUNT_IS_SUCCESS
                  ? setIsWelcome(true)
                  : navigate(ROUTER_PATH.SIGN_IN);
              }}
            >
              <Typography
                align="center"
                fontWeight="300"
                fontSize="1rem"
                color="#fff"
              >
                {UserData?.VERIFY_ACCOUNT_IS_SUCCESS ? "Proceed" : "Go Back"}
              </Typography>
            </Button>
          </Box>
        </Col>
      </Row>
    </div>
  );
}

export default VerifyEmail;
