import PropTypes from "prop-types";

import { Box, Typography } from "../../../Components";
import { IMAGES } from "../../../Assets";
import { AiFillPlayCircle } from "react-icons/ai";
import useWindowSizeHook from "../../../Components/WindowSizeHook";

export default function ReplyTemplate({ ...props }) {
  const { width } = useWindowSizeHook();
  return (
    <div className="d-flex align-items-center justify-content-start my-4">
      {!props.loader && (
        <>
          <img
            src={
              props?.gender === "female"
                ? IMAGES.FEMALE_DOCTOR_AVATAR
                : IMAGES.MALE_DOCTOR_AVATAR
            }
            alt=""
            width={"60px"}
            height={"60px"}
            className="mx-2 rounded-circle chat-image-icon"
          />
          {props?.image ? (
            //   <Box
            //   background="#ffffff"
            //   borderRadius="0.75rem 0.75rem 0.75rem 0"
            //   className="px-1 py-1"
            //   width="fit-content"
            //   maxWidth="50rem"
            // >

            <img
              src={props?.image}
              alt=""
              width="300px"
              height="300px"
              className="mx-2"
            />
          ) : (
            // </Box>
            <Box
              background="#ffffff"
              borderRadius="0.75rem 0.75rem 0.75rem 0"
              className="px-1 py-1"
              width="fit-content"
              maxWidth="50rem"
            >
              <Typography as={width < 600 ? "label" : "p"} className="m-2">
                {props?.reply?.description
                  ? props?.reply?.description?.replace(/<END_OF_TURN>/g, "")
                  : props?.reply}
                {/* {props.reply} */}
              </Typography>
            </Box>
          )}
          {!props?.reply?.description && (
            <Box
              width="1.5rem"
              height="1.5rem"
              background="#9370DB"
              className="rounded-circle d-flex justify-content-center align-items-center mx-2 cursor-pointer"
              // onClick={() => {
              //   props?.resetInActiveTimer();
              //   props?.getAudio(
              //     props.reply,
              //     props?.gender === "female" ? 1 : 0
              //   );
              // }}
              // onClick={() => props?.speakMessage(props.reply, props?.gender)}
            >
              {/* {props?.videoPlaying ? (
                <AiFillPauseCircle
                  color="#ffffff"
                  size={20}
                  onClick={() => {
                    props?.setAudioBlob(null);
                    props?.setVideoPlaying(false);
                    props?.resetInActiveTimer();
                  }}
                />
              ) : ( */}
              <AiFillPlayCircle
                color="#ffffff"
                size={20}
                onClick={() => {
                  // props?.resetInActiveTimer();
                  props?.getAudio(
                    props.reply,
                    props?.gender === "female" ? 1 : 0
                  );
                }}
              />
              {/* )} */}
            </Box>
          )}
        </>
      )}
    </div>
  );
}

ReplyTemplate.propTypes = {
  reply: PropTypes.string.isRequired,
};
