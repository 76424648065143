/* eslint-disable jsx-a11y/img-redundant-alt */
import PropTypes from "prop-types";
import { Box, Typography } from "../../../Components";
import useWindowSizeHook from "../../../Components/WindowSizeHook";
import React from "react";
import { getLoggedInUser } from "../../../utils/helper";


export default function MessageTemplate({ ...props }) {
  const getLocalData = getLoggedInUser();
  const { width } = useWindowSizeHook();

  console.log("props", props);

  return (
    <div className="ps-5 d-flex justify-content-end my-4 align-items-center">
      {/* Render image if it exists */}
      {props?.image ? (
        <img
          src={props.image}
          alt="image"
          width="150px"
          height="150px"
          className="mx-2"
        />
      ) : props?.message ? ( // Render message box if message exists
        <Box
          background="#9370DB"
          borderRadius="0.75rem 0.75rem 0 0.75rem"
          width="fit-content"
          className="px-3 py-1"
        >
          <Typography
            as={width < 600 ? "label" : "p"}
            className="m-2"
            color="#fff"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {props.message.split("\n").map((msg, index) => (
              <React.Fragment key={index}>
                {msg}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </Box>
      ) : null} {/* Fallback to null if neither image nor message exists */}

      {/* User avatar */}
      <img
        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(props?.fromUser || getLocalData?.name)}&background=fff&color=231671`}
        alt=""
        width="60px"
        height="60px"
        className="mx-2 rounded-circle chat-image-icon"
      />
    </div>
  );
}

MessageTemplate.propTypes = {
  message: PropTypes.string.isRequired,
  image: PropTypes.string, // Add image prop type
  fromUser: PropTypes.string, // Add fromUser prop type if necessary
};
