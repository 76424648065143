/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useFormControl } from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

function InputField({
  placeholder = "Placeholder",
  id = "",
  type = "text",
  hasHelperText = false,
  helperText = "",
  error = false,
  ...props
}) {
  const CUSTOM_STYLES = {
    color: props.color || "#969C9A",
    fontSize: props.size || "14px",
    fontStyle: props.style || "normal",
    fontWeight: props.weight || 400,
    lineHeight: props.lineheight || "normal",
    width: props.width || "100%",
    backgroundColor: props.backgroundColor || "#FAFAFA",
    margin: props.margin || "0px !important",
  };
  const { InputProps, ...restProps } = props;
  return (
    <>
      <OutlinedInput
        placeholder={placeholder}
        id={id}
        type={type}
        onChange={props.onChange}
        onBlur={props.onBlur}
        inputProps={{
          style: CUSTOM_STYLES,
          ...InputProps,
          ...restProps,
        }}
        {...restProps}
        error={error}
        defaultValue={props?.defaultValue}
        startAdornment={props?.startAdornment}
        endAdornment={props?.endAdornment}
      />
      {hasHelperText && (
        <MyFormHelperText helperText={helperText} error={error} />
      )}
    </>
  );
}

function MyFormHelperText({ helperText, error }) {
  const { focused } = useFormControl() || {};

  helperText = useMemo(() => {
    if (error) {
      return helperText;
    }
    if (focused) {
      return "This field is being focused";
    }
  }, [error, focused]);

  return <FormHelperText error={error}>{helperText}</FormHelperText>;
}

export default InputField;
