import { useEffect, useState } from "react";

export default function Timer({ expiryTime, handleResendOTP }) {
  console.log("timer", expiryTime);

  const calculateTimeLeft = () => {
    const difference = +new Date(expiryTime) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div className="text-white cursor-pointer float-end my-3 ">
      {timerComponents.length ? (
        <span>Resend OTP After {timerComponents}</span>
      ) : (
        <span onClick={() => handleResendOTP()}>Resend OTP</span>
      )}
    </div>
  );
}
