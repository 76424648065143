// import { FaArrowLeft } from "react-icons/fa";
import { IMAGES } from "../../Assets";
import { Box, Button, Typography } from "../../Components";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTER_PATH } from "../../config";
import useWindowSizeHook from "../../Components/WindowSizeHook";
import { FaArrowRight } from "react-icons/fa6";

export default function ThankYou() {
  const navigate = useNavigate();
  const { width } = useWindowSizeHook();
  const { state } = useLocation();

  console.log("thank you screen message", state);

  const navigateFun = (url) => {
    window.location.href = url;
  };
  return (
    <Box
      width="100%"
      maxWidth="202rem"
      minHeight="50rem"
      borderRadius="1.375rem"
      background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
      shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
      backdropFilter="blur(15px)"
      className="p-5"
    >
      {/* <div>
        <span
          className="text-primary fw-bold d-flex align-items-center justify-content-start  cursor-pointer mb-3"
          onClick={() => navigate(ROUTER_PATH.CHAT_HISTORY)}
        >
          <FaArrowLeft className="me-2" /> Chat History
        </span>
      </div> */}
      <div className="d-flex align-items-center justify-content-between">
        {/* <Typography as="h5" color="#2E3130"> */}
        {/* <span
          className="d-flex align-items-center fw-bold text-primary cursor-pointer mb-3"
          onClick={() => navigate(ROUTER_PATH.CHAT_HISTORY)}
        >
          <FaArrowLeft className="me-2" /> Chat History
        </span> */}
        <span
          className="d-flex align-items-center fw-bold text-primary cursor-pointer mb-3"
          onClick={() =>
            navigate(ROUTER_PATH.CHAT_DETAILS, {
              state: { userChatId: state?.chatId },
            })
          }
        >
          <span>
            Chat Detail{" "}
            <FaArrowRight className="me-2" style={{ marginLeft: "8px" }} />
          </span>
        </span>
        {/* <Button
          height="36px"
          type="button"
          onClick={() =>
            navigate(ROUTER_PATH.CHAT_DETAILS, {
              state: { chatId: state?.chatId },
            })
          }
        >
          <Typography
            align="center"
            fontSize="0.9rem"
            fontWeight="500"
            color="#fff"
          >
            Chat Detail
          </Typography>
        </Button> */}
      </div>

      <div className="d-flex flex-column justify-content-center align-items-center">
        <img
          src={IMAGES.THANKYOU_ICON}
          alt=""
          width={width < 600 ? "100" : "300"}
        />
        <Typography as={width < 600 ? "h6" : "h1"} className="text-center">
          Thank you for the conversation!
        </Typography>
      </div>
      <Typography as={width < 600 ? "label" : "h6"} className="text-center">
        If you’d like to review our discussion and generate a consultation
        report, you can click on “chat details “ above.
        {/* If you'd like to review our discussion and generate a consultation
        report, you can access your chat Detail. */}
      </Typography>
      <div className="d-flex mt-3 justify-content-center align-items-center">
        <Button
          onClick={
            () => navigateFun("https://form.typeform.com/to/CeQtkWw1")
            // navigateFun("https://viwnilw91ea.typeform.com/medistreamline")
          }
          className="text-white "
        >
          Give Feedback
        </Button>
      </div>
    </Box>
  );
}
