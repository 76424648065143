import React from "react";
import { Typography } from "../../../../Components";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import MeasurementLb from "./MeasurementLb";
import MeasurementKg from "./MeasurementKg";

const MeasurementSystems = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const handleUnitChange = (val) => {
    setFieldValue("unitSystem", val);
  };

  return (
    <div>
      {" "}
      <Typography fontSize="1rem" fontWeight="400" color="#9370DB">
        GENERAL INFORMATION
      </Typography>
      <Typography fontSize="1.25rem" fontWeight="500" className="my-3">
        Measurement system
      </Typography>
      <Typography fontSize="0.8rem" fontWeight="300">
        Select your preferred measurement system to accurately record your
        height and weight.
      </Typography>
      <div>
        <ToggleButtonGroup
          type="radio"
          name="options"
          defaultValue={1}
          onChange={handleUnitChange}
          value={values.unitSystem}
          className=" mt-3"
          style={{ background: "whitesmoke", color: "black" }}
        >
          <ToggleButton
            id="tbg-radio-1"
            size="lg"
            value={1}
            variant="outlined-primary"
            className="fs-6"
          >
            lbs/ft/in
          </ToggleButton>
          <ToggleButton
            id="tbg-radio-2"
            size="lg"
            value={2}
            variant="outlined-primary"
            className="fs-6"
          >
            kg/cm
          </ToggleButton>
        </ToggleButtonGroup>
        {values?.unitSystem === 1 ? (
          <>
            <MeasurementLb
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
            />
          </>
        ) : (
          <MeasurementKg
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
        )}
      </div>
    </div>
  );
};

export default MeasurementSystems;
