/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  GenericLoader,
  Toaster,
  Typography,
} from "../../Components";
import { Col, Form, Row } from "react-bootstrap";
import { ROUTER_PATH } from "../../config";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Age from "./components/GeneralInformation/age";
import MeasurementSystems from "./components/GeneralInformation/measurementSystem";
import { IoIosArrowBack } from "react-icons/io";
import EthnicGroup from "./components/GeneralInformation/ethnicGroup";
import Gender from "./components/GeneralInformation/gender";
import Chronic from "./components/MedicalHistory/chronic";
import FamilyHealth from "./components/MedicalHistory/familyHealth";
import KnownAllergies from "./components/MedicalHistory/knownAllergies";
import DailySleep from "./components/LifePatterns/dailySleep";
import StressLevel from "./components/LifePatterns/stressLevel";
import { VerticalProgressBar } from "./components/VerticalProgressbar";
import useWindowSizeHook from "../../Components/WindowSizeHook";
import Surgical from "./components/MedicalHistory/surgical";
import CurrentMedication from "./components/MedicalHistory/currentMedication";
import PrevMedication from "./components/MedicalHistory/prevMedication";
import Smoking from "./components/LifePatterns/smoking";
import Alcohol from "./components/LifePatterns/alcohol";
import DietaryHabits from "./components/LifePatterns/dietaryHabits";
import PhysicalActivity from "./components/LifePatterns/physicalActivity";
// eslint-disable-next-line no-unused-vars
import * as yup from "yup";
import { Formik } from "formik";
import {
  PatientProfileValidationSchemas,
  ProfileInitialValues,
} from "./components/ValidationSchema";
import {
  CREATE_PATIENT_ASSESSMENT,
  GetPatentAssessmentId,
  UPDATE_PATIENT_ASSESSMENT,
} from "../../Redux/actions/patientProfileActions";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowLeft } from "react-icons/fa";
import { getLoggedInUser } from "../../utils/helper";

const HealthDetails = () => {
  const [isFetching, setIsFetching] = useState(true);
  const user = useSelector((state) => state.users);
  const patient = useSelector((state) => state.patientProfile);
  const userPreferredName = user?.PATIENT_ASSESSMENT?.name;
  const userId = user?.PATIENT_ASSESSMENT?.id;
  const userPrimaryGoal = user?.PATIENT_ASSESSMENT_PRIMARY_GOAL;
  const profileAssessmentDataById =
    patient?.GET_PATIENT_ASSESSMENT_ID_DATA?.data;
  const UserProfileId = user?.PATIENT_DATA?.data;
  const { id } = useParams();
  const paramsId = id;
  const location = useLocation();
  const localData = getLoggedInUser();
  let dispatch = useDispatch();
  const localStorageData = JSON.parse(localStorage.getItem("medistreamline"));
  const medUser = JSON.parse(localStorage.getItem("med_user"));

  const renderStep = (props) => {
    const stepProps = { ...props };
    if (progress === 0) return <Age {...stepProps} />;
    else if (progress === 1) return <MeasurementSystems {...stepProps} />;
    else if (progress === 2) return <EthnicGroup {...stepProps} />;
    else if (progress === 3) return <Gender {...stepProps} />;
    else if (progress === 4) return <Chronic {...stepProps} />;
    else if (progress === 5) return <FamilyHealth {...stepProps} />;
    else if (progress === 6) return <KnownAllergies {...stepProps} />;
    else if (progress === 7) return <Surgical {...stepProps} />;
    else if (progress === 8) return <CurrentMedication {...stepProps} />;
    else if (progress === 9) return <PrevMedication {...stepProps} />;
    else if (progress === 10) return <Smoking {...stepProps} />;
    else if (progress === 11) return <Alcohol {...stepProps} />;
    else if (progress === 12) return <DietaryHabits {...stepProps} />;
    else if (progress === 13) return <PhysicalActivity {...stepProps} />;
    else if (progress === 14) return <DailySleep {...stepProps} />;
    else if (progress === 15) return <StressLevel {...stepProps} />;
    // else if (progress === 16) return <VitalMonitoring {...stepProps} />;
  };

  const [progress, setProgress] = useState(0);

  const handleStepChange = (newStep) => {
    setProgress(newStep);
  };

  const UserData = useSelector((state) => state?.users);

  const { width } = useWindowSizeHook();

  const navigate = useNavigate();

  const renderHeadings = () => {
    if ([0, 1, 2, 3].includes(progress)) {
      return (
        <Typography
          fontWeight="400"
          className="text-primary"
          fontSize="1.2rem"
          align="center"
        >
          General Information
        </Typography>
      );
    } else if ([4, 5, 6, 7].includes(progress)) {
      return (
        <Typography
          fontWeight="400"
          className="text-primary "
          fontSize="1.2rem"
          align="center"
        >
          Medication History
        </Typography>
      );
    } else if ([8, 9].includes(progress)) {
      return (
        <Typography
          fontWeight="400"
          className="text-primary"
          fontSize="1.2rem"
          align="center"
        >
          Medications
        </Typography>
      );
    } else if ([10, 11, 12, 13, 14, 15].includes(progress)) {
      return (
        <Typography
          fontWeight="400"
          className="text-primary"
          fontSize="1.2rem"
          align="center"
        >
          Life Patterns and Habits
        </Typography>
      );
      // } else if ([16].includes(progress)) {
      //   return (
      //     <Typography
      //       fontWeight="400"
      //       className="text-primary"
      //       fontSize="1.2rem"
      //       align="center"
      //     >
      //       Vitals Monitoring
      //     </Typography>
      //   );
    }
  };

  const moveToNext = (response) => {
    
    if (paramsId) {
      navigate(ROUTER_PATH.HOME_PAGE);
      localStorage.setItem(
        "med_user",
        JSON.stringify({
          ...localData,
          name: response?.data?.data?.name,
          currentPlanId: response?.data?.data?.planId ? response?.data?.data?.planId : medUser?.planId,
          gender: response?.data.data.sexAssignedId === 101 ? "Male" : "Female",
          occupation: response?.data.data.occupation,
          age: response?.data.data.age,
        })
      );
    } else {
      if (response?.data?.data === null) {
        // Toaster({ message: "Some thing went wrong!", type: "error" });
      } else {
        localStorage.setItem("med_user", JSON.stringify({ ...response?.data?.data, currentPlanId: response?.data.data.planId }));
        navigate(ROUTER_PATH.HOME_PAGE);
      }
    }
  };

  const fetchUserData = useCallback(async () => {
    if (paramsId && isFetching) {
      try {
        // await dispatch(GetPatentAssessmentId(userProfileId));
        await dispatch(GetPatentAssessmentId(paramsId));
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsFetching(false);
      }
    }
  }, [dispatch, paramsId, isFetching]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);
  return (
    <Formik
      initialValues={ProfileInitialValues}
      validationSchema={PatientProfileValidationSchemas[progress]}
      onSubmit={(values, { resetForm }) => {
        if (progress >= 15) {
          const finalObj = {
            patientProfileId:
              paramsId ||
              UserProfileId ||
              userId ||
              UserData?.VERIFY_ACCOUNT_DATA?.data?.userId ||
              localStorageData?.userId,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            name: values?.name,
            mobileContact: values?.mobileContact,

            preferredName: paramsId
              ? profileAssessmentDataById?.preferredName
              : userPreferredName || "",
            isPersonalHealthSight: paramsId
              ? profileAssessmentDataById?.isPersonalHealthSight
              : userPrimaryGoal?.isPersonalHealthSight || true,
            isSomeoneElseHealthAssistance: paramsId
              ? profileAssessmentDataById?.isSomeoneElseHealthAssistance
              : userPrimaryGoal?.isSomeoneElseHealthAssistance || false,
            someoneElseHealthAssistance: paramsId
              ? profileAssessmentDataById?.someoneElseHealthAssistance
              : userPrimaryGoal?.someoneElseHealthAssistance || "",
            isProfessionalUse: paramsId
              ? profileAssessmentDataById?.isProfessionalUse
              : userPrimaryGoal?.isProfessionalUse || true,
            isOtherPrimaryGoal: paramsId
              ? profileAssessmentDataById?.isOtherPrimaryGoal
              : userPrimaryGoal?.isOtherPrimaryGoal || false,
            otherPrimaryGoal: paramsId
              ? profileAssessmentDataById?.otherPrimaryGoal
              : userPrimaryGoal?.otherPrimaryGoal || "",

            occupation: values?.occupation,

            address: values?.address,
            age: parseInt(values?.age),
            isMonth: values?.ageUnit === "months" ? true : false,
            isKg: values?.unitSystem === 2 ? true : false,
            weightMeasurement:
              values?.unitSystem === 2
                ? parseFloat(values?.weightKg)
                : parseFloat(values?.weightLbs),
            heightMeasurement:
              values?.unitSystem === 2
                ? parseFloat(values?.heightCm)
                : parseFloat(values?.heightFt),
            inMeasurement: 0, // pending
            primaryRacialId: parseInt(values?.primaryRacialId),
            primaryRacialDetail: values?.primaryRacialDetail,
            sexAssignedId: values?.sexId,
            menstrualCycleId:
              values?.sexId === 101 ? null : values?.menstrualCycle,
            isChronic: values?.isChronic === 2 ? true : false,
            chronic: values?.medicalHistory,
            isFamilyHealthHistory: values?.familyHealth === 2 ? true : false,
            familyHealthHistory: values?.familyHealthInput,
            isKnownAllergies: values?.allergies === 2 ? true : false,
            knownAllergies: values?.allergiesInput,
            isSurgicalHistory: values?.surgical === 2 ? true : false,
            surgicalHistory: values?.surgicalInput,
            isCurrentMedicaton: values?.currentMedication === 2 ? true : false,
            currentMedicaton: values?.currentMedicationInput,
            isPreviouslyMedication:
              values?.previousMedication === 2 ? true : false,
            previouslyMedication: values?.previousMedicationInput,
            dailySmokingIntensityId: parseInt(values?.smokingId),
            weeklyAlcoholIntakeId: parseInt(values?.alcoholId),
            dietaryHabitId: parseInt(values?.dietary),
            //dietaryInput: "",
            weeklyHabitId: parseInt(values?.weeklyHabit),
            dailySleepPatternId: parseInt(values?.sleepId),
            stressLevelId: parseInt(values?.stressId),
            bloodPressure: parseInt(values?.bloodPressure),
            pulseRate: parseInt(values?.pulseRate),
            temperature: parseInt(values?.temperature),
            glucoLevel: parseInt(values?.glucoLevel),
            respiratory: parseInt(values?.respiratory),
          };
          if (paramsId) {
            dispatch(UPDATE_PATIENT_ASSESSMENT(finalObj, moveToNext));
          } else {
            dispatch(CREATE_PATIENT_ASSESSMENT(finalObj, moveToNext));
          }
          resetForm();
        } else {
          Toaster({ message: "Some thing went wrong!", type: "error" });
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setTouched,
        validateField,
        isValid,
        dirty,
        setFieldValue,
        setFieldError,
        setErrors,
        setFieldTouched,
        setValues,
      }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (
            profileAssessmentDataById ||
            userPreferredName ||
            UserData?.VERIFY_ACCOUNT_DATA ||
            localStorageData
          ) {
            setValues((prevValues) => {
              return {
                ...prevValues,
                name:
                  profileAssessmentDataById?.name ||
                  userPreferredName ||
                  UserData?.VERIFY_ACCOUNT_DATA?.data?.name ||
                  localStorageData?.name ||
                  prevValues.name,
                age: profileAssessmentDataById?.age || prevValues.age,
                mobileContact:
                  profileAssessmentDataById?.mobileContact ||
                  prevValues.mobileContact,
                occupation:
                  profileAssessmentDataById?.occupation ||
                  prevValues.occupation,

                address:
                  profileAssessmentDataById?.address || prevValues?.address,
                bloodPressure:
                  profileAssessmentDataById?.bloodPressure ||
                  prevValues.bloodPressure,
                respiratory:
                  profileAssessmentDataById?.respiratory ||
                  prevValues.respiratory,
                glucoLevel:
                  profileAssessmentDataById?.glucoLevel ||
                  prevValues.glucoLevel,
                pulseRate:
                  profileAssessmentDataById?.pulseRate || prevValues.pulseRate,
                temperature:
                  profileAssessmentDataById?.temperature ||
                  prevValues.temperature,
                ageUnit: profileAssessmentDataById?.isMonth
                  ? "months"
                  : "years",
                unitSystem: profileAssessmentDataById?.isKg ? 2 : 1,
                weightLbs: profileAssessmentDataById?.isKg
                  ? ""
                  : profileAssessmentDataById?.weightMeasurement ||
                  prevValues.weightLbs,
                heightFt: profileAssessmentDataById?.isKg
                  ? ""
                  : profileAssessmentDataById?.heightMeasurement ||
                  prevValues.heightFt,
                weightKg: profileAssessmentDataById?.isKg
                  ? profileAssessmentDataById?.weightMeasurement ||
                  prevValues.weightKg
                  : "",
                heightCm: profileAssessmentDataById?.isKg
                  ? profileAssessmentDataById?.heightMeasurement ||
                  prevValues.heightCm
                  : "",
                medicalHistory: profileAssessmentDataById?.isChronic
                  ? profileAssessmentDataById?.chronic
                  : prevValues.medicalHistory,
                isChronic: profileAssessmentDataById?.isChronic ? 2 : 1,
                familyHealth: profileAssessmentDataById?.isFamilyHealthHistory
                  ? 2
                  : 1,
                familyHealthInput:
                  profileAssessmentDataById?.isFamilyHealthHistory
                    ? profileAssessmentDataById?.familyHealthHistory
                    : prevValues.familyHealthInput,
                allergies: profileAssessmentDataById?.isKnownAllergies ? 2 : 1,
                allergiesInput: profileAssessmentDataById?.isKnownAllergies
                  ? profileAssessmentDataById?.knownAllergies
                  : prevValues.allergiesInput,
                surgical: profileAssessmentDataById?.isSurgicalHistory ? 2 : 1,
                surgicalInput: profileAssessmentDataById?.isSurgicalHistory
                  ? profileAssessmentDataById?.surgicalHistory
                  : prevValues.surgicalInput,
                currentMedication: profileAssessmentDataById?.isCurrentMedicaton
                  ? 2
                  : 1,
                currentMedicationInput:
                  profileAssessmentDataById?.isCurrentMedicaton
                    ? profileAssessmentDataById?.currentMedicaton
                    : prevValues.currentMedicationInput,
                previousMedication:
                  profileAssessmentDataById?.isPreviouslyMedication ? 2 : 1,
                previousMedicationInput:
                  profileAssessmentDataById?.isPreviouslyMedication
                    ? profileAssessmentDataById?.previouslyMedication
                    : prevValues.previousMedicationInput,
                dietary:
                  profileAssessmentDataById?.dietaryHabitId ||
                  prevValues.dietary,
                primaryRacialId:
                  profileAssessmentDataById?.primaryRacialId ||
                  prevValues.primaryRacialId,
                primaryRacialDetail:
                  profileAssessmentDataById?.primaryRacialDetail ||
                  prevValues.primaryRacialDetail,
                sexId:
                  profileAssessmentDataById?.sexAssignedId || prevValues.sexId,

                menstrualCycle:
                  profileAssessmentDataById?.menstrualCycleId ||
                  prevValues.menstrualCycle,

                smokingId:
                  profileAssessmentDataById?.dailySmokingIntensityId ||
                  prevValues.smokingId,
                alcoholId:
                  profileAssessmentDataById?.weeklyAlcoholIntakeId ||
                  prevValues.alcoholId,
                weeklyHabit:
                  profileAssessmentDataById?.weeklyHabitId ||
                  prevValues.weeklyHabit,
                sleepId:
                  profileAssessmentDataById?.dailySleepPatternId ||
                  prevValues.sleepId,
                stressId:
                  profileAssessmentDataById?.stressLevelId ||
                  prevValues.stressId,
              };
            });
          }
        }, [isFetching, profileAssessmentDataById, setValues]);

        return (
          <Form className="d-flex justify-content-center align-items-center">
            <Row className="w-100 flex justify-content-center align-items-center stepper-wrapper  mt-md-0">
              <Col xs={12} lg={10} xxl={8} className="p-0 m-0 ">
                <div>
                  <span
                    className="d-flex align-items-center fw-bold text-primary cursor-pointer mb-3"
                    onClick={() => navigate(-1)}
                  >
                    <FaArrowLeft
                      className={`${location?.pathname === "/welcome/health-details"
                        ? "me-2"
                        : "me-2 text-white"
                        }`}
                    />
                    <p
                      className={`${location?.pathname === "/welcome/health-details"
                        ? "mb-0"
                        : "mb-0 text-white"
                        }`}
                    >
                      Back
                    </p>
                  </span>
                </div>
                <Box
                  width="100%"
                  // width={width < 992 ? "100%" : "50rem"}
                  height="100%"
                  borderRadius="1.375rem"
                  background="#fff"
                  shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.03)"
                  backdropFilter="blur(5px)"
                  className="p-5"
                >
                  <Typography
                    fontWeight="400"
                    fontSize={width > 500 ? "1.25rem" : "1rem"}
                    className={"mb-3"}
                  >
                    <b style={{ color: "#9370DB" }}>
                      {paramsId
                        ? profileAssessmentDataById?.name
                        : userPreferredName ||
                        UserData?.VERIFY_ACCOUNT_DATA?.data?.name ||
                        localStorageData?.name}
                    </b>
                    , what's your primary goal with MediStreamline?
                  </Typography>
                  <Row>
                    <Col
                      md={4}
                      className="d-md-flex align-items-center justify-content-end pe-3"
                    >
                      {width > 768 ? (
                        <div>
                          {/* <div className="steps-content me-2">
                            <Typography
                              fontWeight="400"
                              className="text-primary cursor-pointer"
                              fontSize="0.9rem"
                              align="end"
                              onClick={() => setProgress(16)}
                            >
                              Vitals Monitoring
                            </Typography>
                          </div> */}
                          <div className="steps-content me-2">
                            <Typography
                              fontWeight="400"
                              className="text-primary cursor-pointer"
                              fontSize="0.9rem"
                              align="end"
                              onClick={() => setProgress(10)}
                            >
                              Life Patterns & Habits
                            </Typography>
                          </div>
                          <div className="steps-content me-2">
                            <Typography
                              fontWeight="400"
                              className="text-primary cursor-pointer"
                              fontSize="0.9rem"
                              align="end"
                              onClick={() => setProgress(8)}
                            >
                              Medications{" "}
                            </Typography>
                          </div>
                          <div className="steps-content me-2">
                            <Typography
                              fontWeight="400"
                              className="text-primary cursor-pointer"
                              fontSize="0.9rem"
                              align="end"
                              onClick={() => setProgress(4)}
                            >
                              Medical History
                            </Typography>
                          </div>
                          <div className="steps-content me-2">
                            <Typography
                              fontWeight="400"
                              className="text-primary cursor-pointer"
                              fontSize="0.9rem"
                              align="end"
                              onClick={() => setProgress(0)}
                            >
                              General Information
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <div className="mb-2">{renderHeadings()}</div>
                      )}
                      <div className={width < 768 && "w-100 my-3"}>
                        <VerticalProgressBar
                          progress={progress}
                          horizontal={width < 768}
                        />
                      </div>
                      {/* <div>
                <LinearProgress
                  variant="determinate"
                  value={10}
                  className="vertical-bar"
                />
              </div> */}
                    </Col>
                    <Col md={8}>
                      {renderStep({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        setFieldError,
                        setErrors,
                        setFieldTouched,
                      })}
                      <div className="d-flex justify-content-between align-items-center mt-5">
                        <div
                          className="d-flex align-items-center cursor-pointer"
                          onClick={() => {
                            progress < 1
                              ? navigate(-1)
                              : handleStepChange(Math.max(progress - 1, 0));
                          }}
                        >
                          <IoIosArrowBack className="me-2" color="black" />
                          <Typography
                            align="center"
                            fontWeight="300"
                            fontSize="1rem"
                          >
                            Previous
                          </Typography>
                        </div>

                        <Button
                          type="button"
                          className="px-3"
                          minWidth={width > 500 ? "10rem" : "3rem"}
                          onClick={(e) => {
                            // console.log("err", e.target.value, errors, touched, isValid, dirty, progress)
                            if (isValid && dirty) {
                              if (progress && progress >= 15) {
                                handleSubmit();
                              } else {
                                handleStepChange(Math.min(progress + 1, 16));
                              }
                            } else {
                              const fields = Object.keys(errors);
                              const touchedFields = {};
                              fields.forEach((field) => {
                                touchedFields[field] = true;
                              });
                              setTouched(touchedFields);
                            }
                          }}
                        >
                          <Typography
                            align="center"
                            fontWeight="300"
                            fontSize="1rem"
                            color="#fff"
                            className="text-nowrap"
                          >
                            {patient?.CREATE_PATIENT_ASSESSMENT_IS_LOADING ? (
                              <GenericLoader />
                            ) : progress > 14 ? (
                              "Save & Continue"
                            ) : (
                              "Next"
                            )}
                          </Typography>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Box>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default HealthDetails;
