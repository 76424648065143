const INITIAL_STATES = {
  GET_USER_ID_LOADING: false,
  GET_USER_ID_IS_SUCCESS: false,
  GET_USER_ID_IS_FAILURE: false,
  GET_USER_ID_DATA: [],
  GET_USER_ID_IS_ERROR: null,
  USER_ID: null,

  CREATE_PATIENT_IS_LOADING: false,
  CREATE_PATIENT_IS_SUCCESS: false,
  CREATE_PATIENT_IS_FAILURE: false,
  CREATE_PATIENT_IS_ERROR: null,
  PATIENT_DATA: null,

  VERIFY_ACCOUNT_IS_LOADING: false,
  VERIFY_ACCOUNT_IS_SUCCESS: false,
  VERIFY_ACCOUNT_IS_FAILURE: false,
  VERIFY_ACCOUNT_IS_ERROR: null,
  VERIFY_ACCOUNT_DATA : null,

  SIGN_IN_IS_LOADING: false,
  SIGN_IN_IS_SUCCESS: true,
  SIGN_IN_IS_FAILURE: false,
  USER_DATA: null,
  SIGN_IN_IS_ERROR: null,
 
  SOCIAL_LOG_IN_DATA_IN_IS_LOADING: false,
  SOCIAL_LOG_IN_DATA_IN_IS_SUCCESS: true,
  SOCIAL_LOG_IN_DATA_IN_IS_FAILURE: false,
  SOCIAL_LOG_IN_DATA_DATA: null,
  SOCIAL_LOG_IN_DATA_IN_IS_ERROR: null,


  SEND_OTP_IS_LOADING: false,
  SEND_OTP_IS_SUCCESS: true,
  SEND_OTP_IS_FAILURE: false,
  SEND_OTP_DATA: null,
  SEND_OTP_IS_ERROR: null,

  VERIFY_OTP_IS_LOADING: false,
  VERIFY_OTP_IS_SUCCESS: true,
  VERIFY_OTP_IS_FAILURE: false,
  VERIFY_OTP_DATA: null,
  VERIFY_OTP_IS_ERROR: null,
 
  RESET_PASSWORD_IS_LOADING: false,
  RESET_PASSWORD_IS_SUCCESS: true,
  RESET_PASSWORD_IS_FAILURE: false,
  RESET_PASSWORD_DATA: null,
  RESET_PASSWORD_IS_ERROR: null,

  SIGN_UP_LOADING: false,
  SIGN_UP_SUCCESS: true,
  SIGN_UP_FAILURE: false,
  SIGN_UP_ERROR: null,

  PATIENT_ASSESSMENT: null,
  PATIENT_ASSESSMENT_PRIMARY_GOAL: null,
};

export function userReducer(state = INITIAL_STATES, actions) {
  switch (actions.type) {
    case "GET_USER_ID_REQUEST":
      return {
        ...state,
        GET_USER_ID_LOADING: true,
        GET_USER_ID_IS_SUCCESS: false,
        GET_USER_ID_IS_FAILURE: false,
        GET_USER_ID_DATA: null,
        GET_USER_ID_IS_ERROR: null,
        USER_ID: null,
      };
    case "GET_USER_ID_SUCCESS":
      return {
        ...state,
        GET_USER_ID_LOADING: false,
        GET_USER_ID_IS_SUCCESS: true,
        GET_USER_ID_IS_FAILURE: false,
        GET_USER_ID_DATA: actions.payload,
        USER_ID: actions.payload.user_id,
        GET_USER_ID_IS_ERROR: null,
      };
    case "GET_USER_ID_FAILURE":
      return {
        ...state,
        GET_USER_ID_LOADING: false,
        GET_USER_ID_IS_SUCCESS: false,
        GET_USER_ID_IS_FAILURE: true,
        GET_USER_ID_DATA: null,
        GET_USER_ID_IS_ERROR: actions.payload.message,
        USER_ID: null,
      };


    case "VERIFY_ACCOUNT_REQUEST":
      return {
        ...state,
        VERIFY_ACCOUNT_IS_LOADING: true,
        VERIFY_ACCOUNT_IS_SUCCESS: false,
        VERIFY_ACCOUNT_DATA: null,
        VERIFY_ACCOUNT_IS_ERROR: null,
       
      };
    case "VERIFY_ACCOUNT_SUCCESS":
      return {
        ...state,
        VERIFY_ACCOUNT_IS_LOADING: false,
        VERIFY_ACCOUNT_IS_SUCCESS: true,
        VERIFY_ACCOUNT_DATA: actions.payload,
        VERIFY_ACCOUNT_IS_ERROR: null,
      };
    case "VERIFY_ACCOUNT_FAILURE":
      return {
        ...state,
        VERIFY_ACCOUNT_IS_LOADING: false,
        VERIFY_ACCOUNT_IS_SUCCESS: false,
        VERIFY_ACCOUNT_DATA: null,
        VERIFY_ACCOUNT_IS_ERROR: actions.payload.message,
      };

    case "CREATE_PATIENT_REQUEST":
      return {
        ...state,
        CREATE_PATIENT_IS_LOADING: true,
        CREATE_PATIENT_IS_SUCCESS: false,
        CREATE_PATIENT_IS_FAILURE: false,
        PATIENT_DATA: null,
        CREATE_PATIENT_IS_ERROR: null,
      };
    case "CREATE_PATIENT_SUCCESS":
      return {
        ...state,
        CREATE_PATIENT_IS_LOADING: false,
        CREATE_PATIENT_IS_SUCCESS: true,
        CREATE_PATIENT_IS_FAILURE: false,
        PATIENT_DATA: actions.payload,
        CREATE_PATIENT_IS_ERROR: null,
      };
    case "CREATE_PATIENT_ERROR":
      return {
        ...state,
        CREATE_PATIENT_IS_LOADING: false,
        CREATE_PATIENT_IS_SUCCESS: false,
        CREATE_PATIENT_IS_FAILURE: true,
        PATIENT_DATA: null,
        CREATE_PATIENT_IS_ERROR: actions.payload,
      };

    case "SIGN_IN_REQUEST":
      return {
        ...state,
        SIGN_IN_IS_LOADING: true,
        SIGN_IN_IS_SUCCESS: false,
        SIGN_IN_IS_FAILURE: false,
        USER_DATA: null,
        SIGN_IN_IS_ERROR: null,
      };
    case "SIGN_IN_SUCCESS":
      return {
        ...state,
        SIGN_IN_IS_LOADING: false,
        SIGN_IN_IS_SUCCESS: true,
        SIGN_IN_IS_FAILURE: false,
        USER_DATA: actions.payload,
        SIGN_IN_IS_ERROR: null,
      };
    case "SIGN_IN_ERROR":
      return {
        ...state,
        SIGN_IN_IS_LOADING: false,
        SIGN_IN_IS_SUCCESS: false,
        SIGN_IN_IS_FAILURE: true,
        USER_DATA: null,
        SIGN_IN_IS_ERROR: actions.payload,
      };
    

    case "SOCIAL_LOG_IN_REQUEST":
      return {
        ...state,
        SOCIAL_LOG_IN_IS_LOADING: true,
        SOCIAL_LOG_IN_IS_SUCCESS: false,
        SOCIAL_LOG_IN_IS_FAILURE: false,
        SOCIAL_LOG_IN_DATA: null,
        SOCIAL_LOG_IN_IS_ERROR: null,
      };
    case "SOCIAL_LOG_IN_SUCCESS":
      return {
        ...state,
        SOCIAL_LOG_IN_IS_LOADING: false,
        SOCIAL_LOG_IN_IS_SUCCESS: true,
        SOCIAL_LOG_IN_IS_FAILURE: false,
        SOCIAL_LOG_IN_DATA: actions.payload,
        SOCIAL_LOG_IN_IS_ERROR: null,
      };
    case "SOCIAL_LOG_IN_ERROR":
      return {
        ...state,
        SOCIAL_LOG_IN_IS_LOADING: false,
        SOCIAL_LOG_IN_IS_SUCCESS: false,
        SOCIAL_LOG_IN_IS_FAILURE: true,
        SOCIAL_LOG_IN_DATA: null,
        SOCIAL_LOG_IN_IS_ERROR: actions.payload,
      };




      case "SEND_OTP_REQUEST":
        return {
          ...state,
          SEND_OTP_IS_LOADING: true,
          SEND_OTP_IS_SUCCESS: false,
          SEND_OTP_IS_FAILURE: false,
          SEND_OTP_DATA: null,
          SEND_OTP_IS_ERROR: null,
        };
      case "SEND_OTP_SUCCESS":
        return {
          ...state,
          SEND_OTP_IS_LOADING: false,
          SEND_OTP_IS_SUCCESS: true,
          SEND_OTP_IS_FAILURE: false,
          SEND_OTP_DATA: actions.payload,
          SEND_OTP_IS_ERROR: null,
        };
      case "SEND_OTP_FAILURE":
        return {
          ...state,
          SEND_OTP_IS_LOADING: false,
          SEND_OTP_IS_SUCCESS: false,
          SEND_OTP_IS_FAILURE: true,
          SEND_OTP_DATA: null,
          SEND_OTP_IS_ERROR: actions.payload,
        };




        case "VERIFY_OTP_REQUEST":
          return {
            ...state,
            VERIFY_OTP_IS_LOADING: true,
            VERIFY_OTP_IS_SUCCESS: false,
            VERIFY_OTP_IS_FAILURE: false,
            VERIFY_OTP_DATA: null,
            VERIFY_OTP_IS_ERROR: null,
          };
        case "VERIFY_OTP_SUCCESS":
          return {
            ...state,
            VERIFY_OTP_IS_LOADING: false,
            VERIFY_OTP_IS_SUCCESS: true,
            VERIFY_OTP_IS_FAILURE: false,
            VERIFY_OTP_DATA: actions.payload,
            VERIFY_OTP_IS_ERROR: null,
          };
        case "VERIFY_OTP_FAILURE":
          return {
            ...state,
            VERIFY_OTP_IS_LOADING: false,
            VERIFY_OTP_IS_SUCCESS: false,
            VERIFY_OTP_IS_FAILURE: true,
            VERIFY_OTP_DATA: null,
            VERIFY_OTP_IS_ERROR: actions.payload,
          };

          



          case "RESET_PASSWORD_REQUEST":
            return {
              ...state,
              RESET_PASSWORD_IS_LOADING: true,
              RESET_PASSWORD_IS_SUCCESS: false,
              RESET_PASSWORD_IS_FAILURE: false,
              RESET_PASSWORD_DATA: null,
              RESET_PASSWORD_IS_ERROR: null,
            };
          case "RESET_PASSWORD_SUCCESS":
            return {
              ...state,
              RESET_PASSWORD_IS_LOADING: false,
              RESET_PASSWORD_IS_SUCCESS: true,
              RESET_PASSWORD_IS_FAILURE: false,
              RESET_PASSWORD_DATA: actions.payload,
              RESET_PASSWORD_IS_ERROR: null,
            };
          case "RESET_PASSWORD_FAILURE":
            return {
              ...state,
              RESET_PASSWORD_IS_LOADING: false,
              RESET_PASSWORD_IS_SUCCESS: false,
              RESET_PASSWORD_IS_FAILURE: true,
              RESET_PASSWORD_DATA: null,
              RESET_PASSWORD_IS_ERROR: actions.payload,
            };
    





  
      case "PATIENT_ASSESSMENT_DATA":
      return {
        ...state,
        PATIENT_ASSESSMENT: actions.payload,
      };

    case "PATIENT_ASSESSMENT_PRIMARY_GOAL":
      return {
        ...state,
        PATIENT_ASSESSMENT_PRIMARY_GOAL: actions.payload,
      };
    // case "SIGN_IN_REQUEST":
    //   return {
    //     ...state,
    //     SIGN_IN_IS_LOADING: true,
    //     SIGN_IN_IS_SUCCESS: false,
    //     SIGN_IN_IS_FAILURE: false,
    //     USER_DATA: null,
    //     SIGN_IN_IS_ERROR: null,
    //   };
    // case "SIGN_IN_SUCCESS":
    //   return {
    //     ...state,
    //     SIGN_IN_IS_LOADING: false,
    //     SIGN_IN_IS_SUCCESS: true,
    //     SIGN_IN_IS_FAILURE: false,
    //     USER_DATA: actions.payload,
    //     SIGN_IN_IS_ERROR: null,
    //   };
    // case "SIGN_IN_ERROR":
    //   return {
    //     ...state,
    //     SIGN_IN_IS_LOADING: false,
    //     SIGN_IN_IS_SUCCESS: false,
    //     SIGN_IN_IS_FAILURE: true,
    //     USER_DATA: null,
    //     SIGN_IN_IS_ERROR: actions.payload,
    //   };

    default:
      return state;
  }
}
