import { Toaster } from "../../Components";
import { getUserIdAPI, resetPasswordApi, sendOTPApi, signInAPI, signUpAPI, socialLogInAPI, verifyAccountApi, verifyOTPApi } from "../api/user";

export function getUserId() {
  return (dispatch) => {
    dispatch({
      type: "GET_USER_ID_REQUEST",
    });
    getUserIdAPI().then(
      (response) => {
        dispatch({
          type: "GET_USER_ID_SUCCESS",
          payload: response?.data,
        });
      },
      (error) => {
        dispatch({
          type: "GET_USER_ID_FAILURE",
          payload: error,
        });
      }
    );
  };
}

export function signUp(payload, moveToNext) {
  return (dispatch) => {
    dispatch({
      type: "CREATE_PATIENT_REQUEST",
    });
    signUpAPI(payload).then(
      (response) => {
        if (response.status === 200 ) {
          dispatch({
            type: "CREATE_PATIENT_SUCCESS",
            payload: response.data,
          });
          if (Toaster)
            Toaster({
              message: response?.data?.succeeded===false ?  response?.data?.message : "Please check your inbox to verify your email." ,
              type: response?.data?.data ? "success" : "error",
            });
          if (!response?.data?.data) {
            dispatch({
              type: "CREATE_PATIENT_ERROR",
              payload: response?.data?.data,
            });
          }
          if (moveToNext) moveToNext(response);
        }
      },
      (error) => {
        dispatch({
          type: "CREATE_PATIENT_ERROR",
          payload: error.message,
        });
      }
    );
  };
}

export function signInAction(payload, moveToNext ,moveToAssessmentForm) {
  return (dispatch) => {
    dispatch({
      type: "SIGN_IN_REQUEST",
    });
    signInAPI(payload).then(
      (response) => {
        if (response.status === 200 && response?.data?.data ) {
   
          dispatch({
            type: "SIGN_IN_SUCCESS",
            payload: response.data,
          });
       
          if ((response?.data?.data?.isAssessmentFormFill || response?.data?.data?.roleId===1) && Toaster){ 
            Toaster({
              message: response?.data?.message || "Request Completed Successfully",
              type: response?.data?.data ? "success" : "error",
            })}
            else{
              moveToAssessmentForm(response)
              // const userData = JSON.stringify({ a: response?.data?.data?.name  ,b :response?.data?.data?.userId });
              // window.location.href = `/welcome?data=${encodeURIComponent(userData)}`;
            }
          if ((response?.data?.data?.isAssessmentFormFill || response?.data?.data?.roleId===1) && moveToNext) moveToNext(response);


        } else {
          Toaster({
            message: response?.data?.message,
            type: "error",
          });
          dispatch({
            type: "SIGN_IN_ERROR",
            payload: response?.data?.message,
          });
        }
      },
      (error) => {
        dispatch({
          type: "SIGN_IN_ERROR",
          payload: error.message,
        });
      }
    );
  };
}


export function socialMediaLogInAction(payload, moveToNext, moveToAssessmentForm) {
  return (dispatch) => {
    dispatch({
      type: "SOCIAL_LOG_IN_REQUEST",
    });
    socialLogInAPI(payload).then(
      (response) => {
        if (response.status === 200 && response?.data?.data ) {
      
          dispatch({
            type: "SOCIAL_LOG_IN_SUCCESS",
            payload: response.data,
          });
       
          if ((response?.data?.data?.isAssessmentFormFill || response?.data?.data?.roleId===1) && Toaster){ 
            Toaster({
              message: response?.data?.message || "Request Completed Successfully",
              type: response?.data?.data ? "success" : "error",
            })}
            else{
              moveToAssessmentForm(response)
              // const userData = JSON.stringify({ a: response?.data?.data?.name  ,b :response?.data?.data?.userId });
              // window.location.href = `/welcome?data=${encodeURIComponent(userData)}`;
            }
          if ((response?.data?.data?.isAssessmentFormFill || response?.data?.data?.roleId===1) && moveToNext) moveToNext(response);


        } else {
          Toaster({
            message: response?.data?.message,
            type: "error",
          });
          dispatch({
            type: "SOCIAL_LOG_IN_ERROR",
            payload: response?.data?.message,
          });
        }
      },
      (error) => {
        dispatch({
          type: "SOCIAL_LOG_IN_ERROR",
          payload: error.message,
        });
      }
    );
  };
}
export function verifyAccountAction(payload) {
  return (dispatch) => {
    dispatch({
      type: "VERIFY_ACCOUNT_REQUEST",
    });
    verifyAccountApi(payload).then(
      (response) => {
        if (response.status === 200 && response?.data?.data ) {
      
          dispatch({
            type: "VERIFY_ACCOUNT_SUCCESS",
            payload: response.data,
          });     
          Toaster({  message: response?.data?.message,
            type: "success",}) 

        } else {
          Toaster({
            message: response?.data?.message,
            type: "error",
          });
          dispatch({
            type: "VERIFY_ACCOUNT_FAILURE",
            payload: response?.data?.message,
          });
        }
      },
      (error) => {
        dispatch({
          type: "VERIFY_ACCOUNT_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}


export function sendOTPAction(payload ,moveToNext ,retainHere) {
  return (dispatch) => {
    dispatch({
      type: "SEND_OTP_REQUEST",
    });

    sendOTPApi(payload).then(
      (response) => {
        if (response.status === 200 && response?.data?.data ) {
     
          dispatch({
            type: "SEND_OTP_SUCCESS",
            payload: response.data,
          });     
          Toaster({  message: response?.data?.message,
            type: "success",}) 
          if(moveToNext)moveToNext(response);
          if(retainHere)retainHere(response)

        } else {
          Toaster({
            message: response?.data?.message,
            type: "error",
          });
          dispatch({
            type: "SEND_OTP_FAILURE",
            payload: response?.data?.message,
          });
        }
      },
      (error) => {
        dispatch({
          type: "SEND_OTP_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}
export function verifyOTPAction(payload ,moveToNext) {
  return (dispatch) => {
    dispatch({
      type: "VERIFY_OTP_REQUEST",
    });
    verifyOTPApi(payload).then(
      (response) => {
        if (response.status === 200 && response?.data?.data ) {
      
          dispatch({
            type: "VERIFY_OTP_SUCCESS",
            payload: response.data,
          });     
          Toaster({  message: response?.data?.message,
            type: "success",}) 

        } else {
          Toaster({
            message: response?.data?.message,
            type: "error",
          });
          dispatch({
            type: "VERIFY_OTP_FAILURE",
            payload: response?.data?.message,
          });
          
        }
        moveToNext(response)
      },
      (error) => {
        dispatch({
          type: "VERIFY_OTP_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}
export function resetPasswordAction(payload ,moveToNext) {
  return (dispatch) => {
    dispatch({
      type: "RESET_PASSWORD_REQUEST",
    });
    resetPasswordApi(payload).then(
      (response) => {
        if (response.status === 200 && response?.data?.data ) {
      
          dispatch({
            type: "RESET_PASSWORD_SUCCESS",
            payload: response.data,
          });     
          Toaster({  message: response?.data?.message,
            type: "success",}) 

        } else {
          Toaster({
            message: response?.data?.message,
            type: "error",
          });
          dispatch({
            type: "RESET_PASSWORD_FAILURE",
            payload: response?.data?.message,
          });
        }
        moveToNext(response)
      },
      (error) => {
        dispatch({
          type: "RESET_PASSWORD_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}
