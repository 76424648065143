import PropTypes from "prop-types";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaCaretDown } from "react-icons/fa";
export default function Box({ children, ...props }) {
  const boxStyles = {
    isTooltip: props.isTooltip,
    height: props.height,
    width: props.width,
    border: props.border,
    borderRadius: props.borderRadius,
    borderWidth: props.borderWidth,
    background: props.background,
    maxWidth: props.maxWidth,
    minWidth: props.minWidth,
    boxShadow: props.shadow,
    minHeight: props.minHeight,
    overflowY: props.overflowY,
    flex: props?.flex,
  };
  // eslint-disable-next-line no-undef
  return (
    <div
      onClick={props?.onClick}
      className={` ${
        props?.isTooltip
          ? "text-center d-flex flex-column align-items-center"
          : ""
      }
          ${
            props?.flex
              ? "text-center d-flex justify-content-center align-items-center"
              : ""
          }
          `}
      style={{
        width: props.width === "100%" ? "100%" : "",
        // height: props.height === "100%" ? "100%" : "",
      }}
    >
      {props?.isTooltip && (
        <div
          style={{
            position: "absolute",
            marginTop: "-25px",
          }}
        >
          <small className="badge bg-dark mb-1">Press the mic to speak</small>
          <FaCaretDown
            // className="mb-1"
            size={20}
            style={{
              position: "absolute",
              marginTop: "15px",
              left: "43%",
            }}
          />
        </div>
      )}
      <div {...props} style={boxStyles}>
        {children}
      </div>
    </div>
  );
}

Box.propTypes = {
  width: PropTypes.string,
  isTooltip: PropTypes.bool,
  className: PropTypes.string,
  height: PropTypes.string,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
  border: PropTypes.string,
  borderRadius: PropTypes.string,
  shadow: PropTypes.string,
  background: PropTypes.string,
  children: PropTypes.node,
  overflowY: PropTypes.string,
  flex: PropTypes.bool,
};

Box.defaultProps = {
  width: "fit-content",
  isTooltip: false,
  height: "",
  maxWidth: "",
  maxHeight: "fit-content",
  minHeight: "",
  border: "none",
  borderRadius: "0",
  shadow: "0 0 0 0",
  background: "#ffffff",
  children: null,
  overflowY: "",
  flex: false,
};
