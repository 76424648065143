import React, { useEffect } from "react";
import { Typography, GenericLoader } from "../../../../Components"; // Make sure you have a loader component
import { Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllLookupList } from "../../../../Redux/actions";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

const EthnicGroup = ({ errors, handleChange }) => {
  let dispatch = useDispatch();

  const { values, setFieldValue } = useFormikContext();
  useEffect(() => {
    dispatch(getAllLookupList("PrimaryRacial"));
  }, [dispatch]);

  const assessment = useSelector((state) => state.patientProfile);

  useEffect(() => {
    console.log("Component did mount", values?.primaryRacialId);

    // Cleanup function
    return () => {
      console.log("Component will unmount");
      if (parseInt(values?.primaryRacialId) !== 1008) {
        setFieldValue("primaryRacialDetail", "");
      }
    };
  }, [values?.primaryRacialId, setFieldValue]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setFieldValue("primaryRacialId", value);
  };
  return (
    <div>
      <Typography fontSize="1rem" fontWeight="400" color="#9370DB">
        GENERAL INFORMATION
      </Typography>
      <Typography fontSize="1.25rem" fontWeight="500" className="my-3">
        Primary Racial Or Ethnic Group
      </Typography>
      <Typography fontSize="0.8rem" fontWeight="300">
        Different racial and ethnic groups have varied health tendencies. We use
        this information to provide more accurate health recommendations.
      </Typography>
      {assessment.GET_LOOKUP_LIST_LOADING ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100px" }}
        >
          <GenericLoader />
        </div>
      ) : (
        <Select
          className="w-100 my-4"
          value={values?.primaryRacialId}
          onChange={handleSelectChange}
        >
          {assessment?.GET_LOOKUP_LIST &&
            assessment?.GET_LOOKUP_LIST.map((item) => (
              <MenuItem key={item.lookupId} value={item.lookupId}>
                {item.value}
              </MenuItem>
            ))}
        </Select>
      )}
      {values?.primaryRacialId === 1008 && (
        <Form.Group>
          <Form.Control
            type="text"
            backgroundColor="#EBEBFF"
            name="primaryRacialDetail"
            placeholder="Please Specify"
            className="w-100 border fs-6 box-shadow-0"
            value={values.primaryRacialDetail}
            onChange={handleChange}
            isInvalid={!!errors.primaryRacialDetail}
            size="lg"
          />
          <Form.Control.Feedback type="invalid">
            {errors.primaryRacialDetail}
          </Form.Control.Feedback>
        </Form.Group>
      )}
    </div>
  );
};

export default EthnicGroup;
