import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-dots-loader/index.css";
import "./App.css";
import "./styles/stripeStyle.css";
import "react-toastify/dist/ReactToastify.css";

import AllRoutes from "./Navigation/Routes/Routes";

function App() {
  return (
    <>
      <div id="app-content">
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<AllRoutes />} />
          </Routes>
        </BrowserRouter>
      </div>
      <ToastContainer />
    </>
  );
}

export default App;
