import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  Button,
  GenericLoader,
  InputField,
  Typography,
} from "../../../Components";
import { InputAdornment } from "@mui/material";
import { MdMailOutline } from "react-icons/md";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { sendOTPAction } from "../../../Redux/actions";
import { useNavigate } from "react-router-dom";
import { ROUTER_PATH } from "../../../config";

function ForgotPassword() {
  const user = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SIGN_IN_SCHEMA = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      )
      .required("Please enter your email address"),
  });

  const moveToNext = (response) => {
    navigate(ROUTER_PATH.VERIFY_OTP, {
      state: {
        email: formik?.values?.email,
        expiryTime: response?.data?.data,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: SIGN_IN_SCHEMA,
    onSubmit: (values) => {
      dispatch(
        sendOTPAction(
          {
            email: values?.email,
            isResend: false,
          },
          moveToNext
        )
      );
    },
  });

  return (
    <div>
      <div className={`h-screen`}>
        <Row className="m-0 p-5 vh-100  w-100 align-items-center justify-content-lg-end">
          <Col
            md={12}
            lg={4}
            className="m-0 p-0 d-flex align-items-start flex-column"
          >
            <h1 className="text-white  font-poppins-bold">Forgot Password</h1>
            <p className=" text-white font-poppins-semibold mb-0">
              Enter your Email to Send OTP
            </p>

            <form
              onSubmit={formik.handleSubmit}
              className="container-fluid rounded-1 p-0"
            >
              <Row className="m-0 p-0">
                <Col md={12} className="mt-4 p-0">
                  <InputField
                    type="email"
                    backgroundColor="#EBEBFF"
                    name="email"
                    placeholder="Type your email"
                    className="w-100"
                    onChange={formik.handleChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <MdMailOutline size={20} className="text-light-gray" />
                      </InputAdornment>
                    }
                  />
                  {formik.touched.email && formik.errors.email && (
                    <small className="text-danger small">
                      {formik.errors.email}
                    </small>
                  )}
                </Col>

                <Col md={12} className="m-0 p-0 mt-4">
                  <Button type="submit" className="w-100 signup-button">
                    <Typography
                      fontWeight="500"
                      align="center"
                      fontSize="1rem"
                      color="#fff"
                    >
                      {user?.SEND_OTP_IS_LOADING ? (
                        <GenericLoader />
                      ) : (
                        " Send OTP"
                      )}
                    </Typography>
                  </Button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ForgotPassword;
