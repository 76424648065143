import PropTypes from 'prop-types'

export default function Typography({
  children,
  ...props
}) {
  const typoStyles = {
    fontSize: props.as === 'h1' ? '2.75rem'
            : props.as === 'h2' ? '2.5rem'
            : props.as === 'h3' ? '2.25rem'
            : props.as === 'h4' ? '2rem'
            : props.as === 'h5' ? '1.75rem'
            : props.as === 'h6' ? '1.5rem'
            : props.as === 'p' ? '1rem'
            : props.as === 'label' ? '0.9rem'
            : props.as === 'span' ? '0.75rem'
            : props.fontSize,
    color: props.color,
    fontWeight: (props.as === 'h1' || props.as === 'h2' || props.as === 'h3') ? '700'
              : (props.as === 'h4' || props.as === 'h5' || props.as === 'h6') ? '600'
              : props.as === 'p' ? '500'
              : props.as === 'label' ? '400'
              : props.as === 'span' ? '400'
              : props.fontWeight,
    lineheight: props.lineHeight,
    textAllign: props.align
  }
  return (
    <div {...props} style={typoStyles}>
      {children}
    </div>
  )
}

Typography.propTypes = {
  as: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'label',
    'span'
  ]),
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  lineHeight: PropTypes.string,
  align: PropTypes.string,
}

Typography.defaultProps = {
  as : "",
  color: '#0C0D0D',
  fontSize: '2.5rem',
  lineHeight: 'normal',
  fontWeight: '700',
  align: 'left'
}