import { Formik } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import React from "react";
import { Box, Button, InputField, Typography } from "../../Components";

import { ROUTER_PATH } from "../../config";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function FirstThingFirst({ socialData }) {
	let navigate = useNavigate();
	const UserData = useSelector((state) => state?.users);
	const dispatch = useDispatch();

	function updateUserData(data) {
		return {
			type: "VERIFY_ACCOUNT_SUCCESS",
			payload: data,
		};
	}

	const handleSubmit = async (values) => {
		if (!socialData) {
			const existingData = UserData?.VERIFY_ACCOUNT_DATA?.data || {};

			const updatedData = {
				...existingData,
				name: values?.name,
			};

			dispatch(
				updateUserData({ ...UserData?.VERIFY_ACCOUNT_DATA, data: updatedData })
			);

			localStorage.setItem("medistreamline", JSON.stringify(updatedData));
		}

		navigate(ROUTER_PATH.HEALTH_DETAILS);
	};

	const schema = yup.object().shape({
		name: yup.string().required("This field is required"),
		confirmation: yup.bool().oneOf([true], " ").required(),
	});

	return (
		<div>
			{" "}
			<Formik
				validationSchema={schema}
				onSubmit={handleSubmit}
				initialValues={{
					name:
						UserData?.VERIFY_ACCOUNT_DATA?.data?.name || socialData?.name || "",
					confirmation: false,
				}}
			>
				{({ handleSubmit, handleChange, values, errors }) => (
					<Form noValidate onSubmit={handleSubmit}>
						<Row
							className=" vh-100 flex justify-content-center align-items-center p-3"
							style={{ "--bs-gutter-x": "0" }}
						>
							<Col
								xs={12}
								md={6}
								className="d-flex justify-content-center align-items-center"
							>
								<Box
									// width={width < 992 ? "100%" : "30rem"}
									height="100%"
									borderRadius="1.375rem"
									background="#fff"
									shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.03)"
									backdropFilter="blur(5px)"
									className="p-5"
								>
									<Typography
										fontWeight="500"
										fontSize="1.5rem"
										className="mb-5"
									>
										First Things First 👋
									</Typography>
									<Typography
										fontWeight="400"
										fontSize="1.25rem"
										className="mb-2"
									>
										How should we greet you?
									</Typography>
									<Typography
										fontWeight="200"
										fontSize="0.95rem"
										className="mb-3"
									>
										If privacy is a concern, feel free to use just a nickname.
									</Typography>
									<InputField
										type="text"
										backgroundColor="#EBEBFF"
										name="name"
										value={values?.name}
										// disabled
										placeholder="Preffered nickname or name"
										className="w-100 fs-6"
										onChange={handleChange}
										error={errors?.name}
										hasHelperText
										helperText="This field is required"
										size="16px"
									/>

									<Form.Group className="my-4">
										<Form.Check
											required
											name="confirmation"
											label="I confirm that I am at least 18 years old or I am the legal
              guardian of the user."
											onChange={handleChange}
											isInvalid={!!errors.confirmation}
											feedback={errors.confirmation}
											feedbackType="invalid"
											id="confirmation"
										/>
									</Form.Group>
									<div className="d-flex justify-content-center align-items-center">
										<Button type="submit">
											<Typography
												align="center"
												fontWeight="300"
												fontSize="1rem"
												color="#fff"
											>
												Continue
											</Typography>
										</Button>
									</div>
								</Box>
							</Col>
						</Row>
					</Form>
				)}
			</Formik>
		</div>
	);
}

export default FirstThingFirst;
