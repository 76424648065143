import axios from "axios";
import { API_URLS } from "../../config";

function createPatientAssessmentAPi(data) {
  return axios.post(`${API_URLS.PATIENT_PROFILE.CREATE}`, data);
}
function updatePatientAssessmentAPi(data) {
  return axios.put(`${API_URLS.PATIENT_PROFILE.UPDATE}`, data);
}

function lookupList(id) {
  return axios.get(`${API_URLS.LOOKUP.GET_LOOKUP}?type=${id}`);
}

function getPatentAssessmentApi(id) {
  return axios.get(`${API_URLS.PATIENT_PROFILE.GET}?patientId=${id}`);
}

export {
  createPatientAssessmentAPi,
  updatePatientAssessmentAPi,
  lookupList,
  getPatentAssessmentApi,
};
