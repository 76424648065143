import {
  getBloodOxygen,
  getBloodPressure,
  getGlucose,
  getPulseRate,
  getTemperature,
  getWeight
} from "../api/vitalMonitoringApi"

export function getBloodPressureAction(key, callBack) {
  return (dispatch) => {
    dispatch({
      type: 'GET_BLOOD_PRESSURE_REQUEST'
    })
    getBloodPressure().then(
      (response) => {
        callBack(key, response.data[key])

        dispatch({
          type: 'GET_BLOOD_PRESSURE_SUCCESS',
          payload: response.data
        })
      }, (error) => {
        dispatch({
          type: 'GET_BLOOD_PRESSURE_FAILURE',
          payload: error,
        })
      }
    )
  }
}

export function getPulseRateAction(key, callBack) {
  return (dispatch) => {
    dispatch({
      type: "GET_PULSE_RATE_REQUEST"
    })
    getPulseRate().then(
      (response) => {
        callBack(key, response.data[key])
        dispatch({
          type: "GET_PULSE_RATE_SUCCESS",
          payload: response.data
        })
      }, (error) => {
        dispatch({
          type: "GET_PULSE_RATE_FAILURE",
          payload: error
        })
      }
    )
  }
}

export function getTemperatureAction(key, callBack) {
  return (dispatch) => {
    dispatch({
      type: "GET_TEMPERATURE_REQUEST"
    })
    getTemperature().then(
      (response) => {
        callBack(key, response.data[key])

        dispatch({
          type: 'GET_TEMPERATURE_IS_SUCCESS',
          payload: response.data,
        })
      }, (error) => {
        dispatch({
          tpye: "GET_TEMPERATURE_IS_FAILURE",
          payload: error
        })
      }
    )
  }
}

export function getGlucoseLevelAction(key, callBack) {
  return (dispatch) => {
    dispatch({
      type: "GET_GLUCOSE_LEVEL_REQUEST"
    })
    getGlucose().then(
      (response) => {
        callBack(key, response.data[key])

        dispatch({
          type: "GET_GLUCOSE_LEVEL_SUCCESS",
          payload: response.data,
        })
      }, (error) => {
        dispatch({
          type: "GET_GLUCOSE_LEVEL_FAILURE",
          payload: error
        })
      }
    )
  }
}

export function getBloodOxygenAction(key, callBack) {
  return (dispatch) => {
    dispatch({
      type: "GET_BLOOD_OXYGEN_REQUEST"
    })
    getBloodOxygen().then(
      (response) => {
        callBack("Respiratory", response.data[key])
        // callBack(key, response.data[key])

        dispatch({
          type: 'GET_BLOOD_OXYGEN_SUCCESS',
          payload: response.data,
        })
      }, (error) => [
        dispatch({
          type: "GET_BLOOD_OXYGEN_FAILURE",
          payload: error
        })
      ]
    )
  }
}

export function getWeightAction(key, callBack) {
  return (dispatch) => {
    dispatch({
      type: 'GET_WEIGHT_REQUEST'
    })
    getWeight().then(
      (response) => {
        callBack(key, response.data[key])

        dispatch({
          type: 'GET_WEIGHT_SUCCESS',
          payload: response.data
        })
      }, (error) => {
        dispatch({
          type: 'GET_WEIGHT_FAILURE',
          payload: error,
        })
      }
    )
  }
}