const INITIAL_STATES = {
  CREATE_SUBSCRIPTION_IS_LOADING: false,
  CREATE_SUBSCRIPTION_IS_SUCCESS: false,
  CREATE_SUBSCRIPTION_IS_FALURE: false,
  CREATE_SUBSCRIPTION_IS_ERROR: null,

  CHECK_SUBSCRIPTION_LOADING: false,
  CHECK_SUBSCRIPTION_SUCCESS: false,
  CHECK_SUBSCRIPTION_FALURE: false,
  CHECK_SUBSCRIPTION_ERROR: null,
  SUBSCRIPTION: null,

  GET_ALL_PLANS_LOADING: false,
  GET_ALL_PLANS_SUCCESS: false,
  GET_ALL_PLANS_FALURE: false,
  GET_ALL_PLANS_ERROR: null,
  GET_ALL_PLANS: null,

  CANCEL_SUBSCRIPTION_LOADING: false,
  CANCEL_SUBSCRIPTION_SUCCESS: false,
  CANCEL_SUBSCRIPTION_FALURE: false,
  CANCEL_SUBSCRIPTION_ERROR: null,
  CANCEL_SUBSCRIPTION: null,

};

export function subscriptionReducer(state = INITIAL_STATES, action) {
  switch (action.type) {
    case "CREATE_SUBSCRIPTION_REQUEST":
      return {
        ...state,
        CREATE_SUBSCRIPTION_IS_LOADING: true,
        CREATE_SUBSCRIPTION_IS_SUCCESS: false,
        CREATE_SUBSCRIPTION_IS_FALURE: false,
        CREATE_SUBSCRIPTION_IS_ERROR: null,
      };
    case "CREATE_SUBSCRIPTION_SUCCESS":
      return {
        ...state,
        CREATE_SUBSCRIPTION_IS_LOADING: false,
        CREATE_SUBSCRIPTION_IS_SUCCESS: true,
        CREATE_SUBSCRIPTION_IS_FALURE: false,
        CREATE_SUBSCRIPTION_IS_ERROR: null,
      };
    case "CREATE_SUBSCRIPTION_FAILURE":
      return {
        ...state,
        CREATE_SUBSCRIPTION_IS_LOADING: false,
        CREATE_SUBSCRIPTION_IS_SUCCESS: false,
        CREATE_SUBSCRIPTION_IS_FALURE: true,
        CREATE_SUBSCRIPTION_IS_ERROR: action.payload,
      };

    case "CHECK_SUBSCRIPTION_REQUEST":
      return {
        ...state,
        CHECK_SUBSCRIPTION_LOADING: true,
        CHECK_SUBSCRIPTION_SUCCESS: false,
        CHECK_SUBSCRIPTION_FALURE: false,
        CHECK_SUBSCRIPTION_ERROR: null,
      };
    case "CHECK_SUBSCRIPTION_SUCCESS":
      return {
        ...state,
        CHECK_SUBSCRIPTION_LOADING: false,
        CHECK_SUBSCRIPTION_SUCCESS: true,
        CHECK_SUBSCRIPTION_FALURE: false,
        SUBSCRIPTION: action.payload,
        CHECK_SUBSCRIPTION_ERROR: null,
      };
    case "CHECK_SUBSCRIPTION_FAILURE":
      return {
        ...state,
        CHECK_SUBSCRIPTION_LOADING: false,
        CHECK_SUBSCRIPTION_SUCCESS: false,
        CHECK_SUBSCRIPTION_FALURE: true,
        CHECK_SUBSCRIPTION_ERROR: action.payload,
      };

      case "GET_ALL_PLANS_REQUEST":
        return {
          ...state,
          GET_ALL_PLANS_LOADING: true,
          GET_ALL_PLANS_SUCCESS: false,
          GET_ALL_PLANS_FALURE: false,
          GET_ALL_PLANS_ERROR: null,
        };
      case "GET_ALL_PLANS_SUCCESS":
        return {
          ...state,
          GET_ALL_PLANS_LOADING: false,
          GET_ALL_PLANS_SUCCESS: true,
          GET_ALL_PLANS_FALURE: false,
          GET_ALL_PLANS: action.payload,
          GET_ALL_PLANS_ERROR: null,
        };
      case "GET_ALL_PLANS_FAILURE":
        return {
          ...state,
          GET_ALL_PLANS_LOADING: false,
          GET_ALL_PLANS_SUCCESS: false,
          GET_ALL_PLANS_FALURE: true,
          GET_ALL_PLANS_ERROR: action.payload,
        };

      case "CANCEL_SUBSCRIPTION_REQUEST":
        return {
          ...state,
          CANCEL_SUBSCRIPTION_LOADING: true,
          CANCEL_SUBSCRIPTION_SUCCESS: false,
          CANCEL_SUBSCRIPTION_FALURE: false,
          CANCEL_SUBSCRIPTION_ERROR: null,
        };
      case "CANCEL_SUBSCRIPTION_SUCCESS":
        return {
          ...state,
          CANCEL_SUBSCRIPTION_LOADING: false,
          CANCEL_SUBSCRIPTION_SUCCESS: true,
          CANCEL_SUBSCRIPTION_FALURE: false,
          CANCEL_SUBSCRIPTION: action.payload,
          CANCEL_SUBSCRIPTION_ERROR: null,
        };
      case "CANCEL_SUBSCRIPTION_FAILURE":
        return {
          ...state,
          CANCEL_SUBSCRIPTION_LOADING: false,
          CANCEL_SUBSCRIPTION_SUCCESS: false,
          CANCEL_SUBSCRIPTION_FALURE: true,
          CANCEL_SUBSCRIPTION_ERROR: action.payload,
        };

    default:
      return state;
  }
}
