import PropTypes from 'prop-types'
import { Spinner } from 'react-bootstrap'

export default function GenericLoader({
  ...props
}) {
  return (
    <Spinner
      animation={props.animation}
      varaint={props.varaint}
      size={props.size}
    />
  )
}

GenericLoader.propTypes = {
  animation: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.number,
}

GenericLoader.defaultProps = {
  animation:'grow',
  varaint: 'light',
  size: 10,
}