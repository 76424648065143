import { Toaster } from "../../Components";
import {
  createPatientAssessmentAPi,
  getPatentAssessmentApi,
  lookupList,
  updatePatientAssessmentAPi,
} from "../api/patientProfile";

export function CREATE_PATIENT_ASSESSMENT(payload, moveToNext) {
  return (dispatch) => {
    dispatch({
      type: "CREATE_PATIENT_ASSESSMENT_REQUEST",
    });
    createPatientAssessmentAPi(payload).then(
      (response) => {
        if (response.status === 200 && response?.data?.data ) {
          dispatch({
            type: "CREATE_PATIENT_ASSESSMENT_SUCCESS",
            payload: response.data,
          });
          if (Toaster)
            Toaster({
              message:
                response?.data?.message || "Request completed successfully ",
              type: response?.data?.data ? "success" : "error",
            });
          if (!response?.data?.data) {
            dispatch({
              type: "CREATE_PATIENT_ASSESSMENT_ERROR",
              payload: response?.data?.data,
            });
          }
          if (moveToNext) moveToNext(response);
        }
      },
      (error) => {
        dispatch({
          type: "CREATE_PATIENT_ASSESSMENT_ERROR",
          payload: error.message,
        });
      }
    );
  };
}

export function getAllLookupList(id) {
  return (dispatch) => {
    dispatch({
      type: "GET_LOOKUP_LIST_REQUEST",
    });
    lookupList(id).then(
      (response) => {
        if (response.status === 200) {
          dispatch({
            type: "GET_LOOKUP_LIST_SUCCESS",
            payload: response.data.data,
          });
          if (!response?.data?.data) {
            dispatch({
              type: "GET_LOOKUP_LIST_ERROR",
              payload: response?.data?.data,
            });
          }
        }
      },
      (error) => {
        dispatch({
          type: "GET_LOOKUP_LIST_ERROR",
          payload: error.message,
        });
      }
    );
  };
}

export function GetPatentAssessmentId(id) {
  return (dispatch) => {
    dispatch({
      type: "GET_PATIENT_ASSESSMENT_ID_REQUEST",
    });
    getPatentAssessmentApi(id).then(
      (response) => {
        dispatch({
          type: "GET_PATIENT_ASSESSMENT_ID_SUCCESS",
          payload: response?.data,
        });
      },
      (error) => {
        dispatch({
          type: "GET_PATIENT_ASSESSMENT_ID_FAILURE",
          payload: error,
        });
      }
    );
  };
}

export function UPDATE_PATIENT_ASSESSMENT(payload, moveToNext) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_PATIENT_ASSESSMENT_REQUEST",
    });
    updatePatientAssessmentAPi(payload).then(
      (response) => {
        if (response.status === 200) {
          dispatch({
            type: "UPDATE_PATIENT_ASSESSMENT_SUCCESS",
            payload: response.data,
          });
          if (Toaster)
            Toaster({
              message:
                response?.data?.message || "Request completed successfully ",
              type: response?.data?.data ? "success" : "error",
            });
          if (!response?.data?.data) {
            dispatch({
              type: "UPDATE_PATIENT_ASSESSMENT_ERROR",
              payload: response?.data?.data,
            });
          }
          if (moveToNext) moveToNext(response);
        }
      },
      (error) => {
        dispatch({
          type: "UPDATE_PATIENT_ASSESSMENT_ERROR",
          payload: error.message,
        });
      }
    );
  };
}
